(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage5')
        .controller('AnnualSeasonPlanReportPage5Controller', AnnualSeasonPlanReportPage5Controller);

    function AnnualSeasonPlanReportPage5Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $compile, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanReport');
        vm.columns = [];
        vm.columnsPopup = [];
        vm.egyeni = false;
        vm.editable = true;
        vm.annualSeasonPlanReportId = 0;
        vm.selectedRowIndex = -1;
        vm.selectedRowIndexPopup = -1;

        vm.head = {};
        vm.data = [];
        vm.dataPopup = [];
        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];
        // functions
        vm.goToNextPage = goToNextPage;
        vm.goToPreviousPage = goToPreviousPage;
        vm.newRow = newRow;
        vm.removeRow = removeRow;
        vm.search = search;
        vm.closeOutstandingResultsWindow = closeOutstandingResultsWindow;
        vm.selectOutstandingResults = selectOutstandingResults;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;

        vm.maskObject = {};
        vm.elem = {};

        vm.dateTimeEditorTemplate = {
            create: function () {
                vm.elem = document.createElement('input');
                vm.elem.addEventListener('focus', function () {
                    this.select();
                });
                return vm.elem;
            },
            read: function () {
                return vm.maskObject.getMaskedValue();
            },
            destroy: function () {
                if (vm.maskObject != null) {
                    vm.maskObject.destroy();
                }
            },
            write: function (args) {
                vm.maskObject = new ej2.inputs.MaskedTextBox({
                    minWidth: '100%',
                    mask: '>0000.00.00.',
                    value: args.rowData[args.column.field],
                    readOnly: vm.editable,
                });
                vm.editedRowId = args.rowData['Id'];
                $compile(args.element[0])($scope);
                vm.maskObject.appendTo(vm.elem);
            }
        };

        initGrid();

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            editSettings: {allowEditing: vm.editable, mode: 'Batch', showConfirmDialog: false, allowDeleting: vm.editable, showDeleteConfirmDialog: true},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: vm.editable,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            allowTextWrap: true,
            allowSorting: true,
            allowScrolling: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columnsPopup,
            selectedRowIndex: vm.selectedRowIndexPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        gridPopup.appendTo('#GridPopup');

        activate();

        function activate() {
            vm.jumpedPage = '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives');
            vm.defaultJumpPage = '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives');
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id']),
                    authenticationService.getRight('EDZO'),
                    authenticationService.getRight('ADMIN'),
                    authenticationService.getRight('SZAKOSZTVEZ'),
                    authenticationService.getRight('TECHIGAZGATO'),
                    authenticationService.getRight('EVVERSTALAIR1'),
                    authenticationService.getRight('EVVERSTALAIR2'),
                    authenticationService.getRight('UJEVVERST'),
                    authenticationService.getRight('MEGTSPORTLIST'),
                    authenticationService.getRight('MEGTSPORTLIST'),
                    authenticationService.getRight('MEGTEVVERST')
                ])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.EVVERSTALAIR1 = results[5];
                    vm.EVVERSTALAIR2 = results[6];
                    vm.UJEVVERST = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTSPORTLIST = results[9];
                    vm.MEGTEVVERST = results[10];
                    if (!vm.MEGTEVVERST) {
                        log.permissionError(true);
                        return;
                    }
                    if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                        vm.annualSeasonPlanReportId = results[0]['AnnualSeasonPlanReport.Id'];
                        paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                        dataservice.annualSeasonPlanReportSelectById(vm.annualSeasonPlanReportId)
                            .then(function (data) {
                                vm.head = data;
                                vm.egyeni = data.Egyeni;
                                vm.editable = !data.EdzoLezarta;

                                initGrid();
                                dataservice.annualSeasonPlanCompetitionPlansAndObjectivesSelect(vm.annualSeasonPlanReportId)
                                    .then(function (data) {
                                        vm.data = data.itemsList;
                                        grid.dataSource = vm.data;
                                        grid.editSettings.allowEditing = vm.editable;
                                        grid.editSettings.allowDeleting = vm.editable;
                                    });
                            });
                    } else {
                        $rootScope.back();
                    }
                });
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '5 ') {
                return;
            }

            if (vm.editable) {
                grid.editModule.endEdit();
                dataservice.saveAnnualSeasonPlanCompetitionPlansAndObjectives(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                case '1 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                    break;
                case '2 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                    break;
                case '3 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                    break;
                case '4 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                    break;
                case '5 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                    break;
                case '6 ':
                    $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                    break;
            }
            }, 100);
        }

        function initGrid() {
            vm.columns = [];
            vm.columns = [{
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Sorszam',
                    headerText: gettextCatalog.getString('RowNumber'),
                    textAlign: 'center',
                    width: 40,
                    allowEditing: false
                },
                {
                    field: 'EsemenyIdopont',
                    headerText: gettextCatalog.getString('DateInText'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: vm.editable
                },
                {
                    field: 'EsemenyIdopont2',
                    headerText: gettextCatalog.getString('Datumn'),
                    textAlign: 'center',
                    allowEditing: vm.editable,
                    width: 100,
                    edit: vm.dateTimeEditorTemplate,
                    validationRules: {date: true},
                },
                {
                    field: 'EsemenyNeve',
                    headerText: gettextCatalog.getString('EsemenyNeve'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: vm.editable
                },
                {
                    field: 'EsemenyHelyszin',
                    headerText: gettextCatalog.getString('EsemenyHelye'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable
                },
                {
                    field: 'VersenyzoNeve',
                    headerText: gettextCatalog.getString('VersenyzoNeve'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable
                },
                {
                    field: 'EredmenyTerv',
                    headerText: gettextCatalog.getString('EredmenyTerv'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: vm.editable,
                    visible: !vm.egyeni
                }
            ];

            vm.columnsPopup = [];
            vm.columnsPopup = [{
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Szakosztaly',
                    headerText: gettextCatalog.getString('Section'),
                    textAlign: 'center',
                    width: 100,
                },
                {
                    field: 'Korosztaly',
                    headerText: gettextCatalog.getString('AgeGroup'),
                    textAlign: 'center',
                    width: 100,
                },
                {
                    field: 'DatumTol',
                    headerText: gettextCatalog.getString('DatumTol'),
                    textAlign: 'center',
                    width: 100,
                },
                {
                    field: 'DatumIg',
                    headerText: gettextCatalog.getString('DatumIg'),
                    textAlign: 'center',
                    width: 100,
                }
            ];
        }

        function goToNextPage() {
            if (vm.editable) {
                grid.editModule.endEdit();
                dataservice.saveAnnualSeasonPlanCompetitionPlansAndObjectives(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }

            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
            }, 100);
        }

        function goToPreviousPage() {
            if (vm.editable) {
                grid.editModule.endEdit();
                dataservice.saveAnnualSeasonPlanCompetitionPlansAndObjectives(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
            }, 100);
        }

        function newRow() {
            grid.editModule.endEdit();
            var sorszam = 0;
            for (var i = 0; i < vm.data.length; i++) {
                if (vm.data[i].Sorszam > sorszam) {
                    sorszam = vm.data[i].Sorszam;
                }
            }
            vm.data.push({
                Id: (vm.data.length + 1) * -1,
                Sorszam: sorszam + 1,
                IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                EredmenyTerv: '',
                EsemenyHelyszin: '',
                EsemenyIdopont: '',
                EsemenyIdopont2: '',
                EsemenyNeve: '',
                VersenyzoNeve: ''
            });
            grid.dataSource = vm.data;
        }

        function removeRow() {
            grid.editModule.endEdit();
            vm.selectedRowIndex = grid.selectedRowIndex;
            if (vm.selectedRowIndex > -1) {
                var vmi = vm.data[vm.selectedRowIndex];
                if (vmi.Id > 0) {
                    dataservice.deleteAnnualSeasonPlanCompetitionPlansAndObjective(vmi.Id)
                        .then(function () {
                            deleteRow(vm.selectedRowIndex);
                        });
                } else {
                    deleteRow(vm.selectedRowIndex);
                }
            }
        }

        function deleteRow(index) {
            vm.data.splice(index, 1);
            var i = 1;
            angular.forEach(vm.data, function (value) {
                value.Sorszam = i++;
            });
            grid.dataSource = vm.data;
            grid.refresh();
        }

        function search() {
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();
            dataservice.getAnnualSeasonPlanListByTrainer(vm.head.IdEdzo, vm.annualSeasonPlanReportId)
                .then(function (args) {
                    vm.dataPopup = [];
                    vm.dataPopup = args.itemsList;
                    gridPopup.dataSource = args.itemsList;
                });
        }

        function closeOutstandingResultsWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectOutstandingResults() {
            vm.selectedRowIndexPopup = gridPopup.selectedRowIndex;
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    dataservice.deleteAnnualSeasonPlanCompetitionPlansAndObjectives(vm.annualSeasonPlanReportId)
                        .then(function () {
                            vm.data = [];
                            dataservice.annualSeasonPlanCompetitionPlansAndObjectivesSelect(vmi.Id)
                                .then(function (data) {
                                    angular.forEach(data.itemsList, function (value) {
                                        value.Id = 0;
                                        value.IdVersenyeztetesiTervFej = vm.annualSeasonPlanReportId;
                                        vm.data.push(value);
                                    });
                                    grid.dataSource = vm.data;
                                    log.successMsg('refreshSuccessed');
                                });
                        });
                }
            }
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }
    }
})();
