(function () {
    'use strict';

    angular
        .module('app.reports.centralReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.centralReport',
                config: {
                    title: 'centralReport',
                    url: '/centralReport',
                    templateUrl: 'app/reports/centralReport/centralReport.html',
                    settings: {
                        navId: 234,
                        level: 2,
                        order: 3,
                        orderTitle_hu: 'Központi felmérők',
                        orderTitle_en: 'Central tests',
                        parentId: 4,
                        content: 'CentralReport',
                        activatorPermission: ['"MEGTKPFELM"']
                    }
                }
            }
        ];
    }
})();
