(function () {
    'use strict';

    angular
        .module('app.administration.test.testResults')
        .controller('TestResultsController', TestResultsController);

    function TestResultsController(log, dataservice, authenticationService, $q, $state, $scope, $rootScope, gettextCatalog, paramHelper, $filter) {
        var vm = this;
        $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('TestResults');

        vm.dataSource = [];
        vm.dateValue = new Date();
        vm.enabledDatepicker = false;

        // Grid's variables
        vm.columns = [
            {field: 'FelmerokKozpontiID', headerText: 'Id', textAlign: 'left', isPrimaryKey: true, visible: false},
            {
                field: 'sportoloNeve', headerText: gettextCatalog.getString('AthleteName'),
                textAlign: 'left',
                allowEditing: false,
                width: 135,
                template: '#testresultscolumnTemplate1'
            },
            {
                field: 'szuletesiDatum',
                headerText: gettextCatalog.getString('BirthDate'),
                textAlign: 'center',
                allowEditing: false,
                width: 135
            },
            {
                field: 'szakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'left',
                allowEditing: false,
                width: 135
            },
            {
                field: 'megjelent',
                headerText: gettextCatalog.getString('Appeared'),
                textAlign: 'center',
                width: 135,
                type: 'boolean',
                editType: ej.Grid.EditingType.Boolean,
                displayAsCheckBox: true
            },
            {
                field: 'testmagassag',
                headerText: gettextCatalog.getString('Height'),
                textAlign: 'center',
                editType: 'numericedit',
                width: 135,
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'testsuly',
                headerText: gettextCatalog.getString('Weight'),
                textAlign: 'center',
                editType: 'numericedit',
                width: 135,
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'sulypontEmelkedes1',
                headerText: gettextCatalog.getString('VerticalJump') + ' 1',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'sulypontEmelkedes2',
                headerText: gettextCatalog.getString('VerticalJump') + ' 2',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'sulypontEmelkedes3',
                headerText: gettextCatalog.getString('VerticalJump') + ' 3',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'sulypontEmelkedesLegjobb',
                headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('VerticalJump'),
                textAlign: 'center',
                allowEditing: false,
                width: 135,
                template: '#maxSelect'
            },
            {
                field: 'f30mSprint1',
                headerText: gettextCatalog.getString('30mSprint') + ' 1',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'f30mSprint2',
                headerText: gettextCatalog.getString('30mSprint') + ' 2',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'f30mSprint3',
                headerText: gettextCatalog.getString('30mSprint') + ' 3',
                textAlign: 'center',
                width: 135,
                editType: 'numericedit',
                edit: {params: {decimals: 2, min: 0}},
            },
            {
                field: 'f30mSprintLegjobb',
                headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('30mSprint'),
                textAlign: 'center',
                allowEditing: false,
                width: 135,
                template: '#minSelect'
            },
            {
                field: 'meterPerMeter',
                headerText: gettextCatalog.getString('2800mRunning'),
                textAlign: 'center',
                editTemplate: {
                    create: function () {
                        return '<input>';
                    },
                    read: function (args) {
                        return args.ejTimePicker()[0].value;
                    },
                    write: function (args) {
                        args.element.ejTimePicker({
                            width: '100%',
                            timeFormat: 'HH:mm:ss',
                            interval: 1,
                            enableStrictMode: false
                        });
                    }
                },
                width: 120
            },
            {field: 'megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left', width: 130}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            },
            cellEdit: cellEdit,
            cellSave: cellSave
        });
        grid.appendTo('#Grid');

        vm.lastEditedRow = 0;

        // Publikus funkciók
        vm.calculateBests = calculateBests;
        vm.saveGrid = saveGrid;
        vm.actionBegin = actionBegin;
        vm.goToAthlete = goToAthlete;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MODKPFELM'),
                paramHelper.getParams([], ['test.testId'])]).then(function (results) {
                vm.MODKPFELM = results[0];
                vm.testId = results[1]['test.testId'];
                getTest(vm.testId);
            });
        }

        function actionBegin(args) {
            if (args.requestType && (args.requestType === 'paging' || args.requestType === 'sorting')) {
                args.cancel = true;
                grid.editModule.batchSave();
            }
        }

        function getTest(datum) {
            dataservice.getTest(datum).then(function (data) {
                vm.dataSource = $filter('dateStringFilter')(data.itemsList, 'szuletesiDatum');
                grid.dataSource = vm.dataSource;
            });
        }

        function calculateBests() {
            grid.editModule.batchSave();
        }

        function saveGrid() {
            if (!vm.MODKPFELM) {
                log.permissionError();
            }
            grid.editModule.batchSave();
            return dataservice.modifyTest(grid.dataSource).then(function () {
                log.successMsg('TestResultsSaved');
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function cellSave(args) {
            new ej2.data.DataManager(vm.dataSource).executeQuery(new ej2.data.Query().where('FelmerokKozpontiID', 'equal', args.rowData.FelmerokKozpontiID)).then(function (result) {
                result.result[0][args.columnName] = args.value;
                grid.refresh();
            });
        }

        function cellEdit(args) {
            if (args.columnName === 'megjelent') {
                var index = grid.dataSource.findIndex(function (item) {
                    return item.sportoloId === args.rowData.sportoloId;
                });
                grid.dataSource[index]['megjelent'] = !grid.dataSource[index]['megjelent'];
                grid.endEdit();
                args.cancel = true;
            }
        }
    }
})();
