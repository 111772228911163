(function () {
    'use strict';

    angular
        .module('app.paint')
        .controller('PaintController', DashboardController);

    function DashboardController($q, dataservice, log, DOMAIN, $location, paramHelper, $state, $scope, authenticationService, gettextCatalog, $rootScope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('Paint');

        vm.enabledResizeRotate = false;
        var canvas;
        var ctx;
        var interval = null;

        var WIDTH = $('#canvasWrapper').width() - 17;
        var HEIGHT = WIDTH / 594 * 319;

        var scaleWidth = 1;
        var scaleHeight = 1;
        /*
        Modes:
            0 - Pen
            1 - Select
            2 - Resize
            3 - Rotate
         */
        vm.activeMode = 1;

        var paintId = null;
        vm.public = false;
        vm.sectionDropDownEnabled = true;
        vm.checkBoxEnabled = false;
        vm.deleteEnabled = false;

        vm.clickedElement = null;
        var clickedElementIndex = -1;

        var maxUndoStates = 10;
        var lineSize = 3;
        vm.background = 6;
        var arrowPointRadius = 15;
        var lineId = 0;
        var color = '#000000';
        var textColor = '#ffffff';
        var fontSize = 18;
        var font = fontSize + 'pt Arial';
        vm.label = '';
        var objectCounter = 0;

        var imageWidth = WIDTH;
        var imageHeight = HEIGHT;
        var imageObj = null;

        // Visszavonáshoz
        var stateLast = true;
        vm.stateId = -1;
        var states = [];

        // Animációhoz
        var selectedAnimationId = -1;
        vm.animationStates = [];

        // Mentés és betöltés
        vm.paintName = '';
        vm.sectionId = null;
        vm.agegroupId = null;
        vm.agegroupValue = null;
        vm.ageGroupList = [];

        var lines = [];
        var objects = [];

        function saveState() {
            if (stateLast === false) {
                for (var i = maxUndoStates; i > vm.stateId; i--) {
                    states.splice(i, 1);
                }
                stateLast = true;
            }
            var stateTemp = {lines: $.extend(true, [], lines), objects: $.extend(true, [], objects), lineId: lineId};
            stateTemp.objects.forEach(function (element) {
                element.clicked = false;
            });
            if (vm.stateId < maxUndoStates) {
                states.push(stateTemp);
                vm.stateId++;
            } else {
                states.shift();
                states.push(stateTemp);
            }
            vm.redoEnable = false;
            scopeApply();
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function getAgeGroups() {
            return dataservice.ageGroupSelect(0, vm.sectionId).then(function (data) {
                data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                vm.ageGroupList = data.itemsList;
                $timeout(function () {
                    if (vm.agegroupValue) {
                        angular.element('#agegroupDropDown').ejDropDownList('selectItemByValue', vm.agegroupValue);
                        vm.selectedAgeGroup = vm.agegroupValue;
                    }
                    $scope.$apply();
                }, 100);
            });
        }

        // Run after data bounded
        vm.sectionDataBound = function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        };

        vm.ageGroupDataBound = function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#agegroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.selectedAgeGroup = vm.ageGroupList[0].value;
            } else {
                angular.element('#agegroupDropDown').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
            }
        };

        vm.sectionSelect = function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
        };

        vm.agegroupSelect = function agegroupSelect(args) {
            vm.agegroupId = args.value;
        };

        vm.undo = function undo() {
            if (vm.stateId > 0) {
                stateLast = false;
                vm.stateId--;
                lines = $.extend(true, [], states[vm.stateId].lines);
                objects = $.extend(true, [], states[vm.stateId].objects);
                lineId = states[vm.stateId].lineId;
            }
            vm.redoEnable = true;
        };

        vm.redo = function redo() {
            if (stateLast === false && vm.stateId + 1 < states.length) {
                vm.stateId++;
                lines = $.extend(true, [], states[vm.stateId].lines);
                objects = $.extend(true, [], states[vm.stateId].objects);
                lineId = states[vm.stateId].lineId;
            }
            if (stateLast === false && vm.stateId + 1 < states.length) {
                vm.redoEnable = true;
            } else {
                vm.redoEnable = false;
            }
        };

        var backgrounds = [{
            text: gettextCatalog.getString('FootballPitch'),
            src: '/images/football_pitch.png',
            value: 1
        },
            {text: gettextCatalog.getString('Grass'), src: '/images/green_background.png', value: 2},
            {text: gettextCatalog.getString('BasketballCourt'), src: '/images/basketball_court.png', value: 3},
            {text: gettextCatalog.getString('HandballCourt'), src: '/images/handball_court.png', value: 4},
            {text: gettextCatalog.getString('HockeyRink'), src: '/images/hockey_rink.jpeg', value: 5},
            {text: gettextCatalog.getString('NoBackground'), src: '/images/none.png', value: 6}];

        function init() {
            getSections();
            // Háttér választó
            $('#Background').ejDropDownList({
                dataSource: backgrounds,
                value: vm.background,
                template: '<div><img src="${src}" style="height: 30px;margin-right: 1em;"/>${text}</div>',
                change: backgroundChanged,
                enabled: vm.checkBoxEnabled,
                width: 250
            });

            // Vonal vastagság választó
            $('#LineSize').ejDropDownList({
                dataSource: [{text: gettextCatalog.getString('LineSize1'), src: '/images/line_size_1.png', value: 1},
                    {text: gettextCatalog.getString('LineSize2'), src: '/images/line_size_3.png', value: 3},
                    {text: gettextCatalog.getString('LineSize3'), src: '/images/line_size_5.png', value: 5}],
                value: lineSize,
                template: '<div><img src="${src}"/></div>',
                change: penWidthChanged,
                enabled: vm.checkBoxEnabled,
                width: 200
            });

            // Háttérszínválasztó
            $('#Color').ejColorPicker({
                value: color,
                enableOpacity: false,
                change: colorChanged,
                enabled: vm.checkBoxEnabled,
                modelType: 'Palette'
            });

            // Szövegszínválasztó
            $('#TextColor').ejColorPicker({
                value: textColor,
                enableOpacity: false,
                change: textColorChanged,
                enabled: vm.checkBoxEnabled,
                modelType: 'Palette'
            });

            // Canvas létrehozása
            canvas = document.getElementById('canvas');
            // Szélesség a maximális szélesség
            canvas.width = WIDTH;
            // Magasság alapértelmezett
            canvas.height = WIDTH / 594 * 319;
            // Context mentése
            ctx = canvas.getContext('2d');
            // Vonalvastagás, betűtípus, betűméret beállítása
            ctx.lineSize = lineSize;
            ctx.font = '16px Arial';
            // Click esemény beállítása
            canvas.onmousedown = canvasClicked;

            //paramHelper.setParams([{'Paint.id': 3}]);
            paramHelper.getParams([], ['Paint.id']).then(function (result) {
                paintId = result['Paint.id'];
                // paintId = 3;
                paramHelper.removeParam('Paint.id');
                if (paintId) {
                    dataservice.getPaint({id: paintId}).then(function (res) {
                        vm.authData = authenticationService.getAuthData();
                        vm.sectionDropDownEnabled = false;
                        var response = res.retValue;
                        if (response.userId === parseInt(vm.authData.id)) {
                            vm.checkBoxEnabled = true;
                            vm.deleteEnabled = true;
                            $('#Background').ejDropDownList('enable');
                            $('#LineSize').ejDropDownList('enable');
                            $('#Color').ejColorPicker('enable');
                        }
                        var data = JSON.parse(response.data);
                        vm.paintName = response.paintName;
                        vm.sectionId = response.sectionId;
                        vm.sectionSelect({value: vm.sectionId});
                        vm.agegroupId = response.agegroupId;
                        vm.agegroupValue = response.agegroupId;
                        vm.selectedAgeGroup = response.agegroupId;
                        lineId = data.lineId;
                        objectCounter = data.objectCounter;
                        ctx.scale(WIDTH / data.imageWidth, HEIGHT / data.imageHeight);
                        scaleWidth = WIDTH / data.imageWidth;
                        scaleHeight = HEIGHT / data.imageHeight;
                        imageWidth = data.imageWidth;
                        imageHeight = data.imageHeight;
                        $('#Background').ejDropDownList('selectItemByValue', data.background);
                        vm.public = response.publikus;
                        data.animationStates.forEach(function (el) {
                            el.objects.forEach(function (el2) {
                                if (el2.type === 'ball') {
                                    var tmp = el2.image;
                                    el2.image = new Image();
                                    el2.image.src = tmp;
                                }
                            });
                        });
                        vm.animationStates = data.animationStates;
                        lines = data.lines;
                        data.objects.forEach(function (el) {
                            if (el.type === 'ball') {
                                var tmp = el.image;
                                el.image = new Image();
                                el.image.src = tmp;
                            }
                        });
                        objects = data.objects;
                    });
                }

                // Kirajzolás intervalból hivása
                saveState();
                interval = setInterval(draw, 10);
            });
        }

        // Rajzoló metódusok
        // Kör
        function circle(x, y, r) {
            ctx.beginPath();
            ctx.arc(x, y, r, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.stroke();
        }

        // Téglalap
        function rect(x, y, w, h) {
            ctx.beginPath();
            ctx.rect(x, y, w, h);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        function triangle(x, y, w, h) {
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.moveTo(x, y + h);
            ctx.lineTo(x + w, y + h);
            ctx.lineTo(x + w / 2, y);
            ctx.lineTo(x, y + h);
            ctx.lineTo(x + w, y + h);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        function pentagon(x, y, w) {
            ctx.beginPath();
            ctx.setLineDash([0, 0]);

            var numberOfSides = 5;
            var size = w * 0.6;
            var Xcenter = x;
            var Ycenter = y;

            ctx.beginPath();
            ctx.moveTo(Xcenter + size * Math.cos(0), Ycenter + size * Math.sin(0));

            for (var i = 1; i <= numberOfSides; i += 1) {
                ctx.lineTo(Xcenter + size * Math.cos(i * 2 * Math.PI / numberOfSides), Ycenter + size * Math.sin(i * 2 * Math.PI / numberOfSides));
            }

            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        // Vonal
        function line(x1, y1, x2, y2) {
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        // Szaggatott vonal
        function dashedLine(fromx, fromy, tox, toy) {
            //variables to be used when creating the arrow
            var headlen = 10;

            var angle = Math.atan2(toy - fromy, tox - fromx);

            //starting path of the arrow from the start square to the end square and drawing the stroke
            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox, toy);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
            ctx.beginPath();
            ctx.setLineDash([0, 0]);

            //starting a new path from the head of the arrow to one of the sides of the point
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //path from the side point of the arrow, to the other side point
            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7), toy - headlen * Math.sin(angle + Math.PI / 7));

            //path from the side point back to the tip of the arrow, and then again to the opposite side point
            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //draws the paths created above
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        // Szaggatott vonal
        function dashedLine2(fromx, fromy, tox, toy) {
            //variables to be used when creating the arrow
            var headlen = 10;

            var angle = Math.atan2(toy - fromy, tox - fromx);

            //starting path of the arrow from the start square to the end square and drawing the stroke
            ctx.beginPath();
            ctx.setLineDash([10, 5]);
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox, toy);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
            ctx.beginPath();
            ctx.setLineDash([0, 0]);

            //starting a new path from the head of the arrow to one of the sides of the point
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //path from the side point of the arrow, to the other side point
            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7), toy - headlen * Math.sin(angle + Math.PI / 7));

            //path from the side point back to the tip of the arrow, and then again to the opposite side point
            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //draws the paths created above
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        // Nyíl
        function arrow(fromx, fromy, tox, toy) {
            //variables to be used when creating the arrow
            var headlen = 10;

            var angle = Math.atan2(toy - fromy, tox - fromx);

            //starting path of the arrow from the start square to the end square and drawing the stroke
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox, toy);

            //starting a new path from the head of the arrow to one of the sides of the point
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //path from the side point of the arrow, to the other side point
            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7), toy - headlen * Math.sin(angle + Math.PI / 7));

            //path from the side point back to the tip of the arrow, and then again to the opposite side point
            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //draws the paths created above
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        function sine(fromx, fromy, tox, toy) {
            //variables to be used when creating the arrow

            var oFromx = fromx;
            var oFromy = fromy;

            tox = tox - fromx;
            fromx = 0;
            toy = toy - fromy;
            fromy = 0;

            var headlen = 10;

            var angle = Math.atan2(toy - fromy, tox - fromx);

            //starting path of the arrow from the start square to the end square and drawing the stroke
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.lineJoin = 'round';

            var unit = 3;
            var x = fromx;
            var y = Math.sin(x);

            var cosalfa = Math.cos(angle);
            var sinalfa = Math.sin(angle);

            var length = Math.sqrt(Math.pow(tox - fromx, 2) + Math.pow(toy - fromy, 2));

            ctx.moveTo(fromx + oFromx, unit * y + fromy + oFromy);

            var newX;
            var newY;

            // Loop to draw segments
            for (var i = 0; i <= length; i += 1) {
                x = i / unit;
                y = Math.sin(x);

                var oldX = fromx + i;
                var oldY = unit * y + fromy;

                newX = oldX * cosalfa - oldY * sinalfa;
                newY = oldX * sinalfa + oldY * cosalfa;

                ctx.lineTo(newX + oFromx, newY + oFromy);
            }

            ctx.stroke();
            ctx.closePath();
            ctx.beginPath();

            x = newX + oFromx - headlen / 2 * Math.cos(angle + Math.PI / 2);
            y = newY + oFromy - headlen / 2 * Math.sin(angle + Math.PI / 2);

            //starting a new path from the head of the arrow to one of the sides of the point
            ctx.moveTo(x, y);
            ctx.lineTo(x + headlen * Math.cos(angle + Math.PI / 2), y + headlen * Math.sin(angle + Math.PI / 2));

            //path from the side point of the arrow, to the other side point
            ctx.lineTo(x + headlen * Math.cos(angle + Math.PI / 7), y + headlen * Math.sin(angle + Math.PI / 7));

            //path from the side point back to the tip of the arrow, and then again to the opposite side point
            ctx.lineTo(x, y);
            ctx.lineTo(x + headlen * Math.cos(angle + Math.PI / 2), y + headlen * Math.sin(angle + Math.PI / 2));

            //draws the paths created above
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
        }

        // Canvas törlése
        function clear() {
            ctx.clearRect(0, 0, WIDTH, HEIGHT);
        }

        // Vonalvastagság változik
        function penWidthChanged(args) {
            lineSize = args.value;
        }

        // Szín változik
        function colorChanged(args) {
            color = args.value;
        }

        // Szín változik
        function textColorChanged(args) {
            textColor = args.value;
        }

        // Klikk esemény
        function canvasClicked(e) {
            if (!vm.checkBoxEnabled) {
                return;
            }
            // Ha kijelölő mód aktív
            if (vm.activeMode !== 0) {
                // x, y koordináta meghatározása
                var x = (e.pageX - canvas.offsetLeft) / scaleWidth;
                var y = (e.pageY - canvas.offsetTop) / scaleHeight;
                // előző kijelölés törlése
                clearClicked();
                // kijelölt elem megkeresése
                objects.forEach(function (element, index) {
                    if (element.type === 'rect' || element.type === 'triangle' || element.type === 'pentagon') {
                        if (x < element.x + (element.width / 2) && x > element.x - (element.width / 2) &&
                            y < element.y + (element.height / 2) && y > element.y - (element.height / 2)) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            canvas.onmousemove = moveObject;
                            canvas.onmouseup = moveEnd;
                        } else {
                            element.clicked = false;
                        }
                    } else if (element.type === 'circle') {
                        if (Math.pow(x - element.x, 2) + Math.pow(y - element.y, 2) < Math.pow(element.r, 2)) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            canvas.onmousemove = moveObject;
                            canvas.onmouseup = moveEnd;
                        } else {
                            element.clicked = false;
                        }
                    } else if (element.type === 'arrow' || element.type === 'line' || element.type === 'dline' || element.type === 'dline2' || element.type === 'sine') {
                        if (x < element.x1 + arrowPointRadius && x > element.x1 - arrowPointRadius &&
                            y < element.y1 + arrowPointRadius && y > element.y1 - arrowPointRadius) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            canvas.onmousemove = moveArrowStart;
                            canvas.onmouseup = moveEnd;
                        } else if ((x < element.x2 + arrowPointRadius && x > element.x2 - arrowPointRadius &&
                                y < element.y2 + arrowPointRadius && y > element.y2 - arrowPointRadius)) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            canvas.onmousemove = moveArrorEnd;
                            canvas.onmouseup = moveEnd;
                        } else if (isPointOnSection({x: element.x1, y: element.y1}, {
                                x: element.x2,
                                y: element.y2
                            }, {x: x, y: y})) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            old.x = x;
                            old.y = y;
                            canvas.onmousemove = moveArrow;
                            canvas.onmouseup = moveEnd;
                        } else {
                            element.clicked = false;
                        }
                    } else if (element.type === 'ball') {
                        var w = element.width / 2;
                        var h = element.height / 2;

                        var originX = element.x - w;
                        var originY = element.y - h;

                        var x1 = rotate(originX, originY, element.x - w, element.y + h, element.rotate);
                        var x2 = rotate(originX, originY, element.x + w, element.y + h, element.rotate);
                        var x3 = rotate(originX, originY, element.x + w, element.y - h, element.rotate);
                        var x4 = rotate(originX, originY, element.x - w, element.y - h, element.rotate);

                        // objects.push({
                        //     id: objectCounter++,
                        //     type: 'rect',
                        //     x: x1[0],
                        //     y: x1[1],
                        //     width: 4,
                        //     height: 4,
                        //     color: 'red',
                        //     textColor: textColor,
                        //     clicked: false,
                        //     lineSize: lineSize
                        // });
                        // objects.push({
                        //     id: objectCounter++,
                        //     type: 'rect',
                        //     x: x2[0],
                        //     y: x2[1],
                        //     width: 4,
                        //     height: 4,
                        //     color: 'yellow',
                        //     textColor: textColor,
                        //     clicked: false,
                        //     lineSize: lineSize
                        // });
                        // objects.push({
                        //     id: objectCounter++,
                        //     type: 'rect',
                        //     x: x3[0],
                        //     y: x3[1],
                        //     width: 4,
                        //     height: 4,
                        //     color: 'orange',
                        //     textColor: textColor,
                        //     clicked: false,
                        //     lineSize: lineSize
                        // });
                        // objects.push({
                        //     id: objectCounter++,
                        //     type: 'rect',
                        //     x: x4[0],
                        //     y: x4[1],
                        //     width: 4,
                        //     height: 4,
                        //     color: 'brown',
                        //     textColor: textColor,
                        //     clicked: false,
                        //     lineSize: lineSize
                        // });

                        var maxX = Math.max(x1[0], x2[0], x3[0], x4[0]);
                        var maxY = Math.max(x1[1], x2[1], x3[1], x4[1]);
                        var minX = Math.min(x1[0], x2[0], x3[0], x4[0]);
                        var minY = Math.min(x1[1], x2[1], x3[1], x4[1]);

                        if (x > minX && x < maxX && y > minY && y < maxY) {
                            clearClicked();
                            vm.clickedElement = element;
                            clickedElementIndex = index;
                            vm.clickedElement.clicked = true;
                            vm.label = element.label;
                            if (vm.clickedElement.canResizeRotate) {
                                vm.enabledResizeRotate = true;
                            }
                            if (vm.activeMode === 1) {
                                canvas.onmousemove = moveObject;
                                canvas.onmouseup = moveEnd;
                            } else if (vm.activeMode === 2) {
                                oldResizeX = x;
                                canvas.onmousemove = resizeObject;
                                canvas.onmouseup = moveEnd;
                            } else if (vm.activeMode === 3) {
                                oldRotateX = x;
                                canvas.onmousemove = rotateObject;
                                canvas.onmouseup = moveEnd;
                            }
                        } else {
                            element.clicked = false;
                        }
                    }
                });
                // Ha rajz mód aktív
            } else if (vm.activeMode === 0) {
                lines[lineId] = {points: [], lineSize: lineSize, color: color};
                canvas.onmousemove = drawPen;
                canvas.onmouseup = stopDrawPen;
            }
            $scope.$apply();
        }

        function rotate(cx, cy, x, y, angle) {
            var radians = (Math.PI / 180) * angle;
            var cos = Math.cos(radians);
            var sin = Math.sin(radians);
            var nx = (cos * (x - cx)) - (sin * (y - cy)) + cx;
            var ny = (sin * (x - cx)) + (cos * (y - cy)) + cy;
            return [nx, ny];
        }

        function draw() {
            clear();
            if (imageObj) {
                ctx.drawImage(imageObj, 0, 0, imageWidth, imageHeight);
            }
            var oldFont = ctx.font;
            objects.forEach(function (element) {
                ctx.lineWidth = element.lineSize;
                if (element.clicked === false) {
                    ctx.fillStyle = element.color;
                    ctx.strokeStyle = element.color;
                } else {
                    ctx.fillStyle = element.color;
                    ctx.strokeStyle = '#991111';
                }
                if (element.type === 'rect') {
                    rect(element.x - (element.width / 2), element.y - (element.height / 2), element.width, element.height);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x, element.y + (element.height / 2) - fontSize / 2);
                        ctx.font = oldFont;
                    }
                } else if (element.type === 'pentagon') {
                    pentagon(element.x, element.y, element.width, element.height);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x, element.y + (element.height / 2) - fontSize / 2);
                        ctx.font = oldFont;
                    }
                } else if (element.type === 'triangle') {
                    triangle(element.x - (element.width / 2), element.y - (element.height / 2), element.width, element.height);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x, element.y + (element.height / 2) - 3);
                        ctx.font = oldFont;
                    }
                } else if (element.type === 'line') {
                    line(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                } else if (element.type === 'sine') {
                    sine(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                } else if (element.type === 'dline') {
                    dashedLine(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.y2 < element.y1) {
                            ctx.fillText(element.label, element.x1, element.y1 + 16);
                        } else {
                            ctx.fillText(element.label, element.x1, element.y1 - 5);
                        }
                    }
                } else if (element.type === 'dline2') {
                    dashedLine2(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.y2 < element.y1) {
                            ctx.fillText(element.label, element.x1, element.y1 + 16);
                        } else {
                            ctx.fillText(element.label, element.x1, element.y1 - 5);
                        }
                    }
                } else if (element.type === 'arrow') {
                    arrow(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                } else if (element.type === 'circle') {
                    circle(element.x, element.y, element.r);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x, element.y + 10);
                        ctx.font = oldFont;
                    }
                } else if (element.type === 'ball') {
                    var x = element.x - (element.width / 2);
                    var y = element.y - (element.height / 2);

                    ctx.translate(x, y);
                    ctx.rotate(element.rotate * (Math.PI / 180));
                    ctx.drawImage(element.image, 0, 0, element.width, element.height);

                    if (element.clicked) {
                        ctx.lineWidth = 2;
                        ctx.strokeRect(0, 0, element.width, element.height);
                        ctx.lineWidth = lineSize;
                    }
                    if (element.label) {
                        ctx.fillText(element.label, 0, -5);
                    }
                    ctx.rotate(-element.rotate * (Math.PI / 180));
                    ctx.translate(-x, -y);
                }
            });

            for (var i = 0; i < lines.length; i++) {
                for (var j = 0; j + 1 < lines[i].points.length; j++) {
                    ctx.lineWidth = lines[i].lineSize;
                    ctx.fillStyle = lines[i].color;
                    ctx.strokeStyle = lines[i].color;
                    line(lines[i].points[j].x, lines[i].points[j].y, lines[i].points[j + 1].x, lines[i].points[j + 1].y);
                }
            }
        }

        function drawThis(obs) {
            clear();
            if (imageObj) {
                ctx.drawImage(imageObj, 0, 0, imageWidth, imageHeight);
            }
            obs.objects.forEach(function (element) {
                ctx.lineWidth = element.lineSize;
                if (element.clicked === false) {
                    ctx.fillStyle = element.color;
                    ctx.strokeStyle = element.color;
                } else {
                    ctx.fillStyle = element.color;
                    ctx.strokeStyle = '#991111';
                }
                if (element.type === 'rect') {
                    rect(element.x - (element.width / 2), element.y - (element.height / 2), element.width, element.height);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x - (element.width / 2), element.y - (element.height / 2) - fontSize / 2);
                    }
                    element.x += element.deltaX;
                    element.y += element.deltaY;
                } else if (element.type === 'triangle') {
                    triangle(element.x - (element.width / 2), element.y - (element.height / 2), element.width, element.height);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x, element.y + (element.height / 2) - 3);
                    }
                    element.x += element.deltaX;
                    element.y += element.deltaY;
                } else if (element.type === 'line') {
                    line(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                    element.x1 += element.deltaX1;
                    element.y1 += element.deltaY1;
                    element.x2 += element.deltaX2;
                    element.y2 += element.deltaY2;
                } else if (element.type === 'dline') {
                    dashedLine(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.y2 < element.y1) {
                            ctx.fillText(element.label, element.x1, element.y1 + 16);
                        } else {
                            ctx.fillText(element.label, element.x1, element.y1 - 5);
                        }
                    }
                    element.x1 += element.deltaX1;
                    element.y1 += element.deltaY1;
                    element.x2 += element.deltaX2;
                    element.y2 += element.deltaY2;
                } else if (element.type === 'dline2') {
                    dashedLine2(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.y2 < element.y1) {
                            ctx.fillText(element.label, element.x1, element.y1 + 16);
                        } else {
                            ctx.fillText(element.label, element.x1, element.y1 - 5);
                        }
                    }
                    element.x1 += element.deltaX1;
                    element.y1 += element.deltaY1;
                    element.x2 += element.deltaX2;
                    element.y2 += element.deltaY2;
                } else if (element.type === 'arrow') {
                    arrow(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                    element.x1 += element.deltaX1;
                    element.y1 += element.deltaY1;
                    element.x2 += element.deltaX2;
                    element.y2 += element.deltaY2;
                } else if (element.type === 'sine') {
                    sine(element.x1, element.y1, element.x2, element.y2);
                    if (element.label) {
                        if (element.label) {
                            if (element.y2 < element.y1) {
                                ctx.fillText(element.label, element.x1, element.y1 + 16);
                            } else {
                                ctx.fillText(element.label, element.x1, element.y1 - 5);
                            }
                        }
                    }
                    element.x1 += element.deltaX1;
                    element.y1 += element.deltaY1;
                    element.x2 += element.deltaX2;
                    element.y2 += element.deltaY2;
                } else if (element.type === 'circle') {
                    circle(element.x, element.y, element.r);
                    if (element.label) {
                        ctx.font = font;
                        ctx.fillStyle = element.textColor;
                        ctx.textAlign = 'center';
                        ctx.fillText(element.label, element.x - (element.r / 2), element.y - element.r - 5);
                    }
                    element.x += element.deltaX;
                    element.y += element.deltaY;
                } else if (element.type === 'ball') {
                    var x = element.x - (element.width / 2);
                    var y = element.y - (element.height / 2);

                    ctx.translate(x, y);
                    ctx.rotate(element.rotate * (Math.PI / 180));
                    // ctx.drawImage(element.image, element.x - (element.width / 2), element.y - (element.height / 2), element.width, element.height);
                    ctx.drawImage(element.image, 0, 0, element.width, element.height);

                    if (element.clicked) {
                        ctx.lineWidth = 2;
                        ctx.strokeRect(0, 0, element.width, element.height);
                        ctx.lineWidth = lineSize;
                    }
                    if (element.label) {
                        ctx.fillText(element.label, 0, -5);
                    }
                    ctx.rotate(-element.rotate * (Math.PI / 180));
                    ctx.translate(-x, -y);
                    element.x += element.deltaX;
                    element.y += element.deltaY;
                }
            });

            for (var i = 0; i < obs.lines.length; i++) {
                for (var j = 0; j + 1 < obs.lines[i].points.length; j++) {
                    ctx.lineWidth = obs.lines[i].lineSize;
                    ctx.fillStyle = obs.lines[i].color;
                    ctx.strokeStyle = obs.lines[i].color;
                    line(obs.lines[i].points[j].x, obs.lines[i].points[j].y, obs.lines[i].points[j + 1].x, obs.lines[i].points[j + 1].y);
                }
            }
        }

        // Kijelölés törlése
        function clearClicked() {
            vm.enabledResizeRotate = false;
            vm.label = '';
            vm.clickedElement = null;
            clickedElementIndex = -1;
            objects.forEach(function (element) {
                element.clicked = false;
            });
        }

        var oldResizeX = 0;

        function resizeObject(e) {
            var x = (e.pageX - canvas.offsetLeft) / scaleWidth;

            var resizeScale = vm.clickedElement.height / vm.clickedElement.width;
            vm.clickedElement.width += x - oldResizeX;
            vm.clickedElement.height += (x - oldResizeX) * resizeScale;
            oldResizeX = x;
        }

        var oldRotateX = 0;

        function rotateObject(e) {
            var x = (e.pageX - canvas.offsetLeft) / scaleWidth;
            vm.clickedElement.rotate += (x - oldRotateX) * 2;
            oldRotateX = x;
        }

        // Mozgatások
        function moveObject(e) {
            if (vm.clickedElement.rotate) {
                vm.clickedElement.x = (e.pageX - canvas.offsetLeft) / scaleWidth;
                vm.clickedElement.y = (e.pageY - canvas.offsetTop) / scaleHeight;
            } else {
                vm.clickedElement.x = (e.pageX - canvas.offsetLeft) / scaleWidth;
                vm.clickedElement.y = (e.pageY - canvas.offsetTop) / scaleHeight;
            }
        }

        function moveArrowStart(e) {
            vm.clickedElement.x1 = (e.pageX - canvas.offsetLeft) / scaleWidth;
            vm.clickedElement.y1 = (e.pageY - canvas.offsetTop) / scaleWidth;
        }

        function moveArrorEnd(e) {
            vm.clickedElement.x2 = (e.pageX - canvas.offsetLeft) / scaleWidth;
            vm.clickedElement.y2 = (e.pageY - canvas.offsetTop) / scaleWidth;
        }

        var old = {x: 0, y: 0};

        function moveArrow(e) {
            var x = (e.pageX - canvas.offsetLeft) / scaleWidth;
            var y = (e.pageY - canvas.offsetTop) / scaleWidth;
            vm.clickedElement.x1 += x - old.x;
            vm.clickedElement.y1 += y - old.y;
            vm.clickedElement.x2 += x - old.x;
            vm.clickedElement.y2 += y - old.y;
            old.x = x;
            old.y = y;
        }

        // Vonal új pontjának mentése
        function drawPen(e) {
            var x = e.pageX - canvas.offsetLeft;
            var y = e.pageY - canvas.offsetTop;
            lines[lineId].points.push({x: x, y: y});
        }

        // Aktuális vonal vége
        function stopDrawPen() {
            lineId++;
            canvas.onmousemove = null;
            canvas.onmouseup = null;
            saveState();
        }

        // Mozgatás vége
        function moveEnd() {
            canvas.onmousemove = null;
            canvas.onmouseup = null;
            saveState();
        }

        // Rajzoló mód aktiválása
        vm.pen = function () {
            vm.activeMode = 0;
            clearClicked();
        };

        // Kiválasztó mód aktiválása
        vm.select = function () {
            vm.activeMode = 1;
            clearClicked();
        };

        // Kijelölt elem törlése
        vm.deleteObject = function () {
            if (clickedElementIndex !== -1) {
                clearClicked();
                objects.splice(clickedElementIndex, 1);
                saveState();
            }
        };

        // Hozzáadások
        vm.addRect = function () {
            objects.push({
                id: objectCounter++,
                type: 'rect',
                x: 500,
                y: 100,
                width: 40,
                height: 40,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addTriangle = function () {
            objects.push({
                id: objectCounter++,
                type: 'triangle',
                x: 500,
                y: 100,
                width: 40,
                height: 40,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addPentagon = function () {
            objects.push({
                id: objectCounter++,
                type: 'pentagon',
                x: 500,
                y: 100,
                width: 40,
                height: 40,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addCircle = function () {
            objects.push({
                id: objectCounter++,
                type: 'circle',
                x: 500,
                y: 125,
                r: 20,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addLine = function () {
            objects.push({
                id: objectCounter++,
                type: 'line',
                x1: 500,
                y1: 100,
                x2: 600,
                y2: 100,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addDashedLine = function () {
            objects.push({
                id: objectCounter++,
                type: 'dline',
                x1: 500,
                y1: 100,
                x2: 600,
                y2: 100,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addDashedLine2 = function () {
            objects.push({
                id: objectCounter++,
                type: 'dline2',
                x1: 500,
                y1: 100,
                x2: 600,
                y2: 100,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addArrow = function () {
            objects.push({
                id: objectCounter++,
                type: 'arrow',
                x1: 500,
                y1: 100,
                x2: 600,
                y2: 100,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addSine = function () {
            objects.push({
                id: objectCounter++,
                type: 'sine',
                x1: 500,
                y1: 100,
                x2: 600,
                y2: 100,
                color: color,
                textColor: textColor,
                clicked: false,
                lineSize: lineSize
            });
            saveState();
        };

        vm.addFootball = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 30,
                height: 30,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/football_ball.png';
            objects.push(ball);
            saveState();
        };

        vm.addBasketball = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 30,
                height: 30,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/basketball_ball.png';
            objects.push(ball);
            saveState();
        };

        vm.addHandball = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 30,
                height: 30,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/handball_ball.png';
            objects.push(ball);
            saveState();
        };

        vm.addCone = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 30,
                height: 30,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/cone.png';
            objects.push(ball);
            saveState();
        };

        vm.addHockeyPuck = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 20,
                height: 20,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/hockey_puck.png';
            objects.push(ball);
            saveState();
        };

        vm.addGoal1 = function () {
            var ball = {
                id: objectCounter++,
                type: 'ball',
                image: new Image(),
                x: 500,
                y: 100,
                width: 50,
                height: 100,
                rotate: 0,
                canResizeRotate: true
            };
            ball.image.src = '/images/goal1.png';
            objects.push(ball);
            saveState();
        };

        // Pont és pont távolsága
        function pointPointDistance(p1, p2) {
            return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
        }

        // Pitagorasz (hiányzó befogó hossza)
        function pitagorasz(atfogo, befogo) {
            return Math.sqrt(Math.pow(atfogo, 2) - Math.pow(befogo, 2));
        }

        // Pont a vonalon van?
        function isPointOnSection(p1, p2, p0) {
            // Vonaltól való távolság, amire még megfogható
            var diff = 10;
            // Számításból eredő hiba tűrés
            var errorDiff = 3;
            var sectionDistance = pointPointDistance(p1, p2);
            var d = Math.abs((p2.x - p1.x) * (p1.y - p0.y) - (p1.x - p0.x) * (p2.y - p1.y)) / Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p1.y - p2.y, 2));
            var sectionP1 = pitagorasz(pointPointDistance(p1, p0), d);
            var sectionP2 = pitagorasz(pointPointDistance(p2, p0), d);

            if ((sectionP1 + sectionP2 < (sectionDistance + errorDiff)) && (sectionP1 + sectionP2 > (sectionDistance - errorDiff))) {
                return d < diff;
            }
            return false;
        }

        //
        function backgroundChanged(args) {
            vm.background = args.value;
            if (args.value === 6) {
                imageObj = null;
            } else {
                imageObj = new Image();
                imageObj.src = backgrounds[args.value - 1].src;
            }
        }

        // Minden objektum törlése
        vm.deleteAll = function () {
            imageWidth = WIDTH;
            imageHeight = HEIGHT;
            scaleHeight = 1;
            scaleWidth = 1;
            objects = [];
            lines = [];
            vm.animationStates = [];
            states = [];
            paintId = null;
            vm.sectionDropDownEnabled = true;
            clearClicked();
            stateLast = true;
            vm.stateId = -1;
            selectedAnimationId = -1;
            lineId = 0;
            vm.label = '';
            color = '#000000';
            vm.checkBoxEnabled = true;
            $('#Background').ejDropDownList('enable');
            $('#LineSize').ejDropDownList('enable');
            $('#Color').ejColorPicker('enable');
            $('#TextColor').ejColorPicker('enable');
            vm.deleteEnabled = false;
            scopeApply();
            saveState();
        };

        // Szín beállítása
        vm.getColor = function () {
            if (vm.clickedElement) {
                color = vm.clickedElement.color;
                $('#Color').ejColorPicker({
                    value: vm.clickedElement.color,
                    enableOpacity: false,
                    change: colorChanged,
                    modelType: 'Palette'
                });
            }
        };

        vm.changedLabel = function () {
            vm.clickedElement.label = vm.label;
            saveState();
        };

        vm.addAnimation = function () {
            clearClicked();
            selectedAnimationId++;
            var stateTemp = {lines: $.extend(true, [], lines), objects: $.extend(true, [], objects), lineId: lineId};
            stateTemp.objects.forEach(function (element) {
                element.clicked = false;
            });
            vm.animationStates.push(stateTemp);
        };

        vm.saveAnimation = function () {
            if (selectedAnimationId >= 0) {
                vm.animationStates[selectedAnimationId].lines = $.extend(true, [], lines);
                vm.animationStates[selectedAnimationId].objects = $.extend(true, [], objects);
                vm.animationStates[selectedAnimationId].lineId = lineId;
            }
        };

        function scopeApply() {
            $timeout(function () {
                $scope.$apply();
            }, 0);
        }

        vm.deleteAnimation = function () {
            clearClicked();
            if (selectedAnimationId > -1) {
                vm.animationStates.splice(selectedAnimationId, 1);
                vm.selectAnimation(vm.animationStates.length - 1);
                scopeApply();
            }
        };

        vm.selectAnimation = function (index) {
            clearClicked();
            if (index > -1) {
                selectedAnimationId = index;
                lines = $.extend(true, [], vm.animationStates[index].lines);
                objects = $.extend(true, [], vm.animationStates[index].objects);
                lineId = vm.animationStates[index].lineId;
            }
        };

        var fps = 60;
        var drawTime = 1000 / fps;
        var intervalTimes = 0;
        var timePerState = 2;
        var animationList = [];
        var stateId = 0;
        vm.play = function (speed) {
            clearClicked();
            timePerState = speed;
            intervalTimes = 0;
            animationList = [];
            stateId = 0;
            // Csak akkor van animáció, amikor legalább két állapot van
            var statesLength = vm.animationStates.length;
            if (statesLength > 1) {
                for (var i = 0; i < (statesLength - 1); i++) {
                    animationList.push({objects: [], lines: []});
                    vm.animationStates[i].objects.forEach(function (element) {
                        // Ha kell animálni
                        var nextElement = vm.animationStates[i + 1].objects.find(function (nextElement) {
                            return element.id === nextElement.id;
                        });
                        if (nextElement !== undefined) {
                            // deltaX és deltaY kiszámolása
                            if (element.type === 'rect' || element.type === 'circle' || element.type === 'ball') {
                                var tmp = $.extend(true, [], element);
                                tmp.deltaX = (nextElement.x - element.x) / (timePerState * fps);
                                tmp.deltaY = (nextElement.y - element.y) / (timePerState * fps);
                                animationList[i].objects.push(tmp);
                            } else if (element.type === 'line' || element.type === 'dline' || element.type === 'dline2' || element.type === 'arrow' || element.type === 'sine') {
                                var tmp = $.extend(true, [], element);
                                tmp.deltaX1 = (nextElement.x1 - element.x1) / (timePerState * fps);
                                tmp.deltaY1 = (nextElement.y1 - element.y1) / (timePerState * fps);
                                tmp.deltaX2 = (nextElement.x2 - element.x2) / (timePerState * fps);
                                tmp.deltaY2 = (nextElement.y2 - element.y2) / (timePerState * fps);
                                animationList[i].objects.push(tmp);
                            }
                            animationList[i].lines = $.extend(true, [], vm.animationStates[i].lines);
                        }
                    });
                }
                clearInterval(interval);
                interval = setInterval(drawAnimation, drawTime);
                stateId = 0;
                intervalTimes = 0;
            }
        };

        function drawAnimation() {
            if (intervalTimes >= (vm.animationStates.length - 1) * timePerState * fps) {
                clearInterval(interval);
                interval = setInterval(draw, 10);
                vm.selectAnimation(vm.animationStates.length - 1);
            } else {
                if (intervalTimes % (timePerState * fps) === 0) {
                    stateId++;
                }
                intervalTimes++;
                drawThis(animationList[stateId - 1]);
            }
        }

        vm.save = function () {
            $timeout(function () {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg('RequiredFieldsError');
                } else {
                    var data = {
                        lineId: lineId,
                        objectCounter: objectCounter,
                        background: vm.background,
                        imageWidth: imageWidth,
                        imageHeight: imageHeight,
                        animationStates: $.extend(true, [], vm.animationStates),
                        lines: lines,
                        objects: $.extend(true, [], objects)
                    };
                    data.objects.forEach(function (el) {
                        if (el.type === 'ball') {
                            el.image = el.image.src;
                        }
                    });
                    data.animationStates.forEach(function (el) {
                        el.objects.forEach(function (el2) {
                            if (el2.type === 'ball') {
                                el2.image = el2.image.src;
                            }
                        });
                    });
                    if (paintId) {
                        dataservice.modifyPaint({
                            paintId: paintId,
                            publikus: vm.public,
                            data: JSON.stringify(data)
                        }).then(function () {
                            vm.sectionDropDownEnabled = false;
                            log.successMsg('PaintModified');
                        });
                    } else {
                        dataservice.savePaint({
                            paintName: vm.paintName,
                            sectionId: vm.sectionId,
                            agegroupId: vm.agegroupId,
                            data: JSON.stringify(data)
                        }).then(function (data) {
                            paintId = data.res;
                            vm.deleteEnabled = true;
                            vm.sectionDropDownEnabled = false;
                            log.successMsg('PaintSaved');
                        });
                    }
                }
            }, 0);
        };

        vm.publicCheckboxChanged = function (args) {
            vm.public = args.value;
        };

        vm.deletePaint = function () {
            swal({
                title: gettextCatalog.getString('DoYouWantDelete'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('YesIWantDelete'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.deletePaint({
                        id: paintId
                    }).then(function () {
                        vm.deleteAll();
                        log.successMsg('PaintDeleted');
                    });
                }
            });
        };

        vm.resizeObject = function () {
            vm.activeMode = 2;
        };

        vm.rotateObject = function () {
            vm.activeMode = 3;
        };

        interval = init();
    }
})();
