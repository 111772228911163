(function () {
    'use strict';

    angular
        .module('app.reports.centralReport.centralReportAllIn')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.centralReport.centralReportAllIn',
                config: {
                    url: '/centralReportAllIn',
                    templateUrl: 'app/reports/centralReport/centralReportAllIn/centralReportAllIn.html',
                    controller: 'CentralReportAllInController',
                    controllerAs: 'vm',
                    title: 'CentralReportAllIn',
                    settings: {
                        navId: 31234,
                        level: 3,
                        order: 1,
                        orderTitle_hu: 'Központi felmérők összesített kimutatás',
                        orderTitle_en: 'Central Tests Aggregate Report',
                        parentId: 234,
                        content: 'CentralReportAllIn',
                        activatorPermission: '"MEGTKPFELM"'
                    }
                }
            }
        ];
    }
})();
