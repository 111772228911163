(function () {
    'use strict';

    angular
        .module('app.reports.centralReport.centralReportByRating')
        .controller('CentralReportByRatingController', CentralReportByRatingController);

    function CentralReportByRatingController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        $rootScope.title = gettextCatalog.getString('CentralReportByRating');
        vm.tests = [];
        vm.sexList = [
            {text: gettextCatalog.getString('AllGender'), value: 'all'},
            {text: gettextCatalog.getString('Male'), value: 'male'},
            {text: gettextCatalog.getString('Female'), value: 'female'}
        ];

        vm.columns = [{
            field: 'SportoloNeve',
            headerText: gettextCatalog.getString('Name'),
            textAlign: 'left',
            template: '#cerebyratemplateDataColumn1',
            width: 120
        }, {
            field: 'SportoloDatum',
            headerText: gettextCatalog.getString('BirthDate'),
            textAlign: 'left',
            width: 120
        }, {
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section'),
            textAlign: 'left',
            width: 120
        }, {
            field: 'KorosztalyNeve',
            headerText: gettextCatalog.getString('AgeGroup'),
            textAlign: 'left',
            width: 120
        }, {
            field: 'EdzoNeve',
            headerText: gettextCatalog.getString('Coach'),
            textAlign: 'left',
            template: '#cerebyratemplateDataColumn2',
            width: 120
        }, {
            field: 'SulypontLegjobb',
            headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('VerticalJump'),
            textAlign: 'left',
            width: 120
        }, {
            field: 'SprintLegjobb',
            headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('30mSprint'),
            textAlign: 'left',
            width: 120
        }, {
            field: 'MeterLegjobbStr',
            headerText: gettextCatalog.getString('2800mRunning'),
            textAlign: 'left',
            width: 120
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Date picker
        vm.defaultDateTest = 0;
        vm.selectedDateTest = '';
        vm.dateTestList = [];

        // Coach
        vm.defaultCoach = 0;
        vm.selectedCoach = vm.defaultCoach;
        vm.coachList = [];

        // Szakosztály
        vm.defaultSection = 0;
        vm.selectedSection = vm.defaultSection;
        vm.sectionList = [];

        // korosztály
        vm.defaultAgeGroup = 0;
        vm.selectedAgeGroup = vm.defaultAgeGroup;
        vm.ageGroupList = [];

        // Felmérési szám
        vm.defaultTestNumber = 0;
        vm.selectedTestNumber = vm.defaultTestNumber;
        vm.testNumberList = [];
        vm.buttonEnabled = false;

        vm.testNumber = -1;
        vm.sex = 'all';
        vm.dateTestSelest = dateTestSelest;
        vm.dateTestDataBound = dateTestDataBound;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;

        vm.getData = getData;

        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTKPFELM')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getFelmerokDatumai2DropDownList();
                    getSections();
                    setDropDowns();
                }
            })
                .catch(function () {
                    log.permissionError(true);
                });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'centralReportByRating.sectionId',
                'centralReportByRating.ageGroupId',
                'centralReportByRating.coachId',
                'centralReportByRating.sex',
                'centralReportByRating.selectedDateTest'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.defaultSection = results[0]['centralReportByRating.sectionId'];
                vm.defaultAgeGroup = results[0]['centralReportByRating.ageGroupId'];
                vm.defaultCoach = results[0]['centralReportByRating.coachId'];
                vm.sex = results[0]['centralReportByRating.sex'];
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(vm.selectedCoach, true)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        function getAgeGroups(sectionId) {
            if (!sectionId || sectionId < 1) {
                sectionId = 0;
            }
            return dataservice.ageGroupDropDownList(sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.ageGroupList.splice(0, 0, all);
                    }
                });
        }

        function getCoaches(sectionId, ageGroupId) {
            if (!ageGroupId || ageGroupId == -1) {
                ageGroupId = 0;
            }
            return dataservice.coachDropDownList(sectionId, ageGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function sectionSelect(args) {
            getAgeGroups(args.value);
            getCoaches(args.value, 0);
        }

        function ageGroupSelect(args) {
            getCoaches(vm.selectedSection, args.value);
        }

        function coachSelect() {
            // vm.selectedCoach = args.value;
        }

        function dateTestSelest(arg) {
            vm.selectedDateTest = arg.text;
            vm.buttonEnabled = true;
        }

        function getData() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
            } else {
                paramHelper.setParams([
                    {'centralReportByRating.sectionId': vm.selectedSection},
                    {'centralReportByRating.ageGroupId': vm.selectedAgeGroup},
                    {'centralReportByRating.coachId': vm.selectedCoach},
                    {'centralReportByRating.sex': vm.sex},
                ]);
                vm.selectedDateTest = angular.element('#datepickerTest').ejDropDownList('getSelectedItem')[0].innerText;
                return dataservice.centralReportByRating(vm.selectedSection, vm.selectedAgeGroup, vm.selectedCoach, vm.sex, vm.selectedDateTest)
                    .then(function (data) {
                        var dataSource = data.itemsList;
                        grid.dataSource = $filter('dateStringFilter')(dataSource, 'SportoloDatum');
                    });
            }
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getFelmerokDatumai2DropDownList() {
            dataservice.getFelmerokDatumai2DropDownList()
                .then(function (data) {
                    vm.dateTestList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.defaultSection);
            }
        }

        function ageGroupDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.defaultAgeGroup);
                if (vm.selectedAgeGroup) {
                    getCoaches(vm.selectedSection, 0);
                }
            }
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.defaultCoach);
            }
        }

        function dateTestDataBound() {
            if (vm.dateTestList.length === 1) {
                angular.element('#datepickerTest').ejDropDownList('selectItemByValue', vm.dateTestList[0].value);
            } else {
                angular.element('#datepickerTest').ejDropDownList('selectItemByValue', vm.defaultdatepickerTest);
            }
        }
    }
})();
