(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('SidebarController', SidebarController);

    function SidebarController($state, authenticationService, localStorageService, dataservice, $rootScope, $scope) {
        var vm = this;
        vm.isCurrent = isCurrent;
        vm.noEducationalFee = localStorageService.get('noEducationalFee');
        vm.isAuthenticated = authenticationService.isAuthenticated();
        vm.permission = authenticationService.getPermission();
        vm.authenticated = authenticationService.isAuthenticated();
        vm.rights = authenticationService.getAuthData().accessRights;
        vm.checkPermissionGroup = checkPermissionGroup;
        $rootScope.INAKTIVMENUREJTES = true;
        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }
        $scope.$on('msgRead', getUnreadMessageCount);
        getSystemSettings('INAKTIVMENUREJTES').then(function (res) {
            $rootScope.INAKTIVMENUREJTES = res;
        });
        vm.menuItemClicked = menuItemClicked;
        vm.messageCount = 0;
        getUnreadMessageCount();
        function checkPermissionGroup(activators) {
            for (var i = 0; i < activators.length; i++) {
                if (vm.rights.indexOf(activators[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }

        function getUnreadMessageCount() {
            dataservice.GetUnreadMessageCount().then(function(data) {
                vm.messageCount = data.res;
            });
        };

        function menuItemClicked(nav, level) {
            if (nav.settings.childRoutes === undefined || nav.settings.childRoutes.length === 0) {
                if (level === 1) {
                    vm.level1Open = 0;
                    vm.level2Open = 0;
                }
                if (window.innerWidth < 768) {
                    $rootScope.$broadcast('autoCloseSideBar');
                }
                $state.go(nav.name);
            } else {
                switch (level) {
                    case 1:
                        if (nav.settings.navId === vm.level1Open) {
                            vm.level1Open = 0;
                        } else {
                            vm.level1Open = nav.settings.navId;
                            vm.level2Open = 0;
                        }
                        break;
                    case 2:
                        if (nav.settings.navId === vm.level2Open) {
                            vm.level2Open = 0;
                        } else {
                            vm.level2Open = nav.settings.navId;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        function isCurrent(route) {
            if (!route.title || !$state.current || !$state.current.title) {
                return '';
            }
            var menuName = route.title;
            return $state.current.title === menuName ? 'current' : '';
        }
    }
})();
