(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.centralReportOfAthlete')
        .controller('CentralReportOfAthleteController', CentralReportOfAthleteController);

    function CentralReportOfAthleteController(dataservice, $q, $rootScope, gettextCatalog, paramHelper, $scope) {
        var vm = this; $rootScope.vm = vm;

        vm.columns = [{
            field: 'Felmero',
            headerText: gettextCatalog.getString('CentralAssessors')
        }, {
            field: 'SulypontLegjobb',
            headerText: gettextCatalog.getString('VerticalJump')
        }, {
            field: 'SulypontValtozas',
            headerText: gettextCatalog.getString('VerticalJumpChange')
        }, {
            field: 'SprintLegjobb',
            headerText: gettextCatalog.getString('Sprint')
        }, {
            field: 'SprintValtozas',
            headerText: gettextCatalog.getString('SprintChange')
        }, {
            field: 'MeterLegjobbStr',
            headerText: gettextCatalog.getString('m2800')
        }, {
            field: 'MeterValtozasStr',
            headerText: gettextCatalog.getString('m2800Change')
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])])
                .then(function (results) {
                    vm.sportoloId = results[0]['athleteModify.sportoloId'];
                    vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('CentralReportOfAthlete');
                    getData(vm.sportoloId, vm.sportoloNev);
                });
        }

        function getData(id, name) {
            $rootScope.title = name + ' - ' + gettextCatalog.getString('CentralReportOfAthlete');
            return dataservice.centralReportOfAthlete(id)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }
    }
})();
