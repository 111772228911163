(function () {
        'use strict';

        angular
            .module('app.messageCenter')
            .controller('MessageCenterController', MessageCenterController);

        function MessageCenterController($q, $rootScope, gettextCatalog, dataservice, $scope, log, authenticationService, paramHelper, $state, $timeout, DOMAIN) {
            var vm = this;
            $rootScope.vm = vm;
            $rootScope.title = gettextCatalog.getString('MessageCenterRecievedMessages');
            //Grid nyomtatás, exportálás nyomógomb
            vm.isBusy = false;
            vm.listPageNumber = 1;
            vm.itemsPerMessage = 24;
            vm.messagePageNumber = 1;
            vm.listItemsPerPage = 24;
            vm.SelectedMessage = {};
            vm.openedMessageTargyId = null;
            vm.SelectedMessageIndex = null;
            vm.SelectedMessageDateSource = null;
            vm.toolbarClick = toolbarClick;
            vm.loadMessageId = -1;
            vm.profileImgBaseUrl = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl;
            vm.messageImagesUrl = DOMAIN.baseURL + DOMAIN.messageImagesUrl;
            vm.imageSet = imageSet;

            activate();

            vm.EditMessageText = gettextCatalog.getString('Directory');
            vm.Uzenet = gettextCatalog.getString('Message'); // cts-textarea-hoz kell, onnan nem veszi fel a fordítást...

            function activate() {
                setInterval(WaitForPushNotifications, 500);
                vm.toolbarItems = [
                    {
                        empid: 1,
                        tooltiptext: gettextCatalog.getString('msgSendNew'),
                        spriteCss: 'msgtools newmsg',
                        group: '1'
                    },
                    {
                        empid: 4,
                        tooltiptext: gettextCatalog.getString('Remove'),
                        spriteCss: 'msgtools msgdelete',
                        group: '2'
                    },
                    {
                        empid: 5,
                        tooltiptext: gettextCatalog.getString('Print'),
                        spriteCss: 'msgtools msgprint',
                        group: '3'
                    },
                    {
                        empid: 6,
                        tooltiptext: gettextCatalog.getString('Refresh'),
                        spriteCss: 'msgtools msgrefresh',
                        group: '3'
                    }
                    // , {
                    //     empid: 7,
                    //     tooltiptext: gettextCatalog.getString('Sent'),
                    //     spriteCss: 'msgtools msgsent',
                    //     group: '3'
                    // }
                ];
                vm.dataMessages = [];

                $q.all([paramHelper.getParams([], ['messageTargyId']), authenticationService.getRight('MEGTFELH'), authenticationService.getRight('UZENETMEGT'), authenticationService.getRight('UZENETTORLES')]).then(function (results) {
                    vm.MEGTFELH = results[1];
                    vm.UZENETMEGT = results[2];
                    vm.UZENETTORLES = results[3];
                    if (results[0].messageTargyId) {
                        vm.openedMessageTargyId = results[0].messageTargyId;
                        paramHelper.removeParam('messageTargyId');
                    }
                    if (!vm.UZENETMEGT) {
                        log.permissionError(true);
                        return;
                    }
                    refreshList();
                    angular.element(document.querySelector('#message-list')).bind('scroll', function () {
                        var objDiv = jQuery($('#message-list'));
                        if (objDiv[0].scrollTop == objDiv[0].scrollHeight - objDiv[0].clientHeight) {
                            vm.listPageNumber += 1;
                            loadMoreListItem();
                        }
                    });
                    angular.element(document.querySelector('#text')).bind('keypress', function (e) {
                        if ((e.which === 10 || e.which === 13) && e.shiftKey === false) {
                            vm.messageSend();
                        }
                    });
                    $timeout(function () {
                        if (vm.openedMessageTargyId > 0) {
                            getMessages(vm.openedMessageTargyId);
                        }
                    }, 100);
                });
            }

            function WaitForPushNotifications() {
                var pushnotification = localStorage.getItem('pushnotification');
                if (pushnotification && pushnotification.length > 0) {
                    localStorage.removeItem('pushnotification');
                    var payload = JSON.parse(pushnotification);
                    if (payload.data.page == 'messages') {
                        refreshList();
                        if (parseInt(payload.data.contentid) == parseInt(vm.openedMessageTargyId)) {
                            getMessages(vm.openedMessageTargyId);
                        }
                    }
                }
            }

            function toolbarClick(params) {
                switch (params.currentTarget.id) {
                    case '1':
                        paramHelper.setParams([{
                            'newMessage.Message': '',
                            'newMessage.OriginalSenderId': 0,
                            'newMessage.OriginalSenderName': ''
                        }
                        ]);
                        $state.go('messageCenter.newMessage');
                        break;
                    case '4':
                        if (!vm.openedMessageTargyId) {
                            log.errorMsg('Válasszon ki egy üzenetet!');
                            return;
                        }
                        swal({
                            title: gettextCatalog.getString('DoYouWantDelete'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: gettextCatalog.getString('YesIWantDelete'),
                            cancelButtonText: gettextCatalog.getString('Cancel'),
                        }).then(function (isConfirm) {
                            if (!vm.UZENETTORLES) {
                                log.errorMsg('Nincs joga az üzenetet törléséhez!');
                                return;
                            }
                            if (isConfirm.value) {
                                dataservice.SikerMobileFreeDeleteMessages(vm.openedMessageTargyId).then(function () {
                                    log.infoMsg('MessageMarkAsDeleted');
                                    refreshList();
                                    vm.openedMessageTargyId = null;
                                    vm.SelectedMessageDateSource = null;
                                    $timeout(function () {
                                        $scope.$apply();
                                    }, 10);
                                    vm.SelectedMessage = {};
                                });
                            }
                        });
                        break;
                    case '5':
                        if (!vm.SelectedMessage.Id) {
                            log.errorMsg('Válasszon ki egy üzenetet!');
                            return;
                        }
                        var newWindow = window.open();
                        var x = document.getElementById('printDiv').innerHTML;

                        var w = '<head><title>Test</title><link rel=\"stylesheet\" href=\"/.tmp/style.css\">' + x + '</head><body>';
                        newWindow.document.write(w);
                        // newWindow.print();
                        newWindow.print();
                        $timeout(function () {
                            newWindow.close();
                        }, 100);
                        break;
                    case '6':
                        vm.SelectedMessage.Id = null;
                        vm.SelectedMessage.Uzenet = '';
                        vm.SelectedMessage.FeladoNev = '';
                        vm.SelectedMessage.Datum = '';
                        refreshList();
                        getMessages(vm.openedMessageTargyId);
                        break;
                    case '7':
                        if (!vm.SelectedMessage.TargyId) {
                            log.errorMsg('Válasszon ki egy üzenetet!');
                            return;
                        } else {
                            vm.messageSend();
                        }
                }
            }

            function refreshList() {
                var pageNumber = vm.listPageNumber * vm.listItemsPerPage;
                dataservice.GetMessageGroups(1, pageNumber).then(function (data) {
                    vm.dataMessages = [];
                    vm.dataMessages = data.itemsList;
                    for (var i = 0; i < vm.dataMessages.length; i++) {
                        if (vm.dataMessages[i]['TargyId'] == vm.openedMessageTargyId) {
                            vm.recipients = vm.dataMessages[i]['Kikkel'];
                            break;
                        }
                    }
                });
            }

            vm.messageClicked = function (index) {
                vm.isBusy = false;
                vm.messagePageNumber = 1;
                vm.SelectedMessage = vm.dataMessages[index];
                vm.openedMessageTargyId = vm.SelectedMessage.TargyId;
                vm.selectedMessageIndex = index;
                vm.recipients = vm.dataMessages[index].Kikkel;

                paramHelper.setParams([{
                    'messageTargyId': vm.openedMessageTargyId
                }]);
                if (!vm.SelectedMessage.Olvasott) {
                    dataservice.messageReaded(vm.SelectedMessage.Id).then(function () {
                        vm.SelectedMessage.Olvasott = true;
                        $rootScope.$broadcast('msgRead');
                    });
                } else {
                    setTimeout(function () {
                        $scope.$apply();
                    }, 10);
                }
                getMessages(vm.openedMessageTargyId);
            };

            function getMessages(targyId) {
                dataservice.getMessages(targyId).then(function (result) {
                    vm.SelectedMessageDateSource = result.itemsList;
                    imageSet(vm.SelectedMessageDateSource);
                    angular.element(document.querySelector('#message-detail')).bind('scroll', function () {
                        var objDiv = jQuery($('#message-detail'));
                        if (objDiv[0].scrollTop === 0 && vm.isBusy === false) {
                            vm.messagePageNumber += 1;
                            vm.isBusy = true;
                            loadMoreMessage();
                        }
                    });
                });
            }

            vm.messageOpen = function (id) {
                vm.messageViewed(id);
                paramHelper.setParams([{'messageId': id}]);
                $state.go('messageCenter.messageCenter');
            };

            vm.messageViewed = function (id) {
                dataservice.unreadedMessagesViewed(id).then(function () {
                    log.infoMsg('MessageMarkedAsRead');
                });
            };

            vm.messageSend = function () {
                if (!vm.openedMessageTargyId) {
                    log.errorMsg('Válasszon ki egy üzenetet!');
                } else {
                    if (!vm.Message) {
                        return;
                    }
                    var parameters = {SubjectId: vm.openedMessageTargyId, Message: vm.Message};
                    vm.Message = null;
                    dataservice.SikerMobileFreeSendMessage(parameters).then(function (response) {
                        if (response.retValue) {
                            log.successMsg(gettextCatalog.getString('SendSuccessful'));
                            getMessages(vm.openedMessageTargyId);
                            vm.selectedMessageIndex = 0;
                            refreshList();
                        }
                    });
                }
            };

            vm.finished = function () {
                var objDiv = $('#message-detail');
                // ne kerülön vissza a
                if (vm.messagePageNumber === 1) {
                    objDiv[0].scrollTop = objDiv[0].scrollHeight;
                }
            };

            function loadMoreListItem() {
                dataservice.GetMessageGroups(vm.listPageNumber).then(function (resp) {
                    if (resp.itemsList.length === 0) {
                        vm.listPageNumber -= 1;
                        angular.element(document.querySelector('#message-list')).unbind('scroll');
                        return;
                    }
                    resp.itemsList.forEach(function (item) {
                        vm.dataMessages.push(item);
                    });
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                });
            };

            function loadMoreMessage() {
                dataservice.getMessages(vm.openedMessageTargyId, vm.messagePageNumber, vm.itemsPerMessage).then(function (resp) {
                    if (resp.itemsList.length === 0) {
                        vm.listPageNumber += 1;
                        angular.element(document.querySelector('#message-detail')).unbind('scroll');
                        return;
                    }
                    var objDivBefore = $('#message-detail');
                    var rowSizeBeforeLoadMore = objDivBefore[0].scrollHeight - objDivBefore[0].clientHeight;
                    resp.itemsList.reverse().forEach(function (item) {
                        vm.SelectedMessageDateSource.unshift(item);
                    });

                    $timeout(function () {
                        vm.isBusy = false;
                        var objDivAfter = $('#message-detail');
                        var rowSizeAfterLoadMore = objDivAfter[0].scrollHeight - objDivAfter[0].clientHeight;
                        objDivAfter[0].scrollTop = rowSizeAfterLoadMore - rowSizeBeforeLoadMore;
                        $scope.$apply();
                    }, 0);
                    imageSet(vm.SelectedMessageDateSource);
                });
            };

            function imageSet(array) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i]['images'] != null) {
                        for (var j = 0; j < array[i]['images'].length; j++) {
                            array[i]['images'][j] = array[i]['images'][j].replace('_thumb', '');
                        }
                    }
                }
                $timeout(function() {
                    $('.message-wrapper').each(function() {
                        $(this).magnificPopup({
                            delegate: 'a',
                            type: 'image',
                            gallery: {
                                enabled: true
                            }
                        });
                    });
                }, 100);
            }
        }
    }

)();
