(function () {
    'use strict';

    angular
        .module('app.administration.weeklyTrainingPlan.weeklyTrainingPlanReport')
        .controller('WeeklyTrainingPlanReportController', WeeklyTrainingPlanReportController);

    function WeeklyTrainingPlanReportController(dataservice, $q, $rootScope, gettextCatalog, paramHelper, log, $scope, authenticationService, $state, $compile, $timeout, $filter, $sce) {
        var vm = this;
        $rootScope.vm = vm;

        $rootScope.title = gettextCatalog.getString('Weekly Training Plan');
        vm.EditText = gettextCatalog.getString('Edit');
        var printOpened = false;
        var printOpened2 = false;
        vm.trainingPlanId = -1;
        vm.editedWTP = {};
        vm.editedWTP.Hetfo = undefined;
        vm.hetfodatum = undefined;
        vm.copyEnabled = false;
        vm.save = save;
        vm.files = openFiles;
        vm.print = showPrint;
        vm.print2 = showPrint2;
        vm.MondayChange = MondayChange;
        vm.copyFromOldPlan = copyFromOldPlan;
        vm.editTraining = editTraining;
        vm.columnsPopup = [];
        vm.selectedRowIndexPopup = -1;
        vm.athleteListHeight = 0;
        vm.selectedRowIndex = -1;
        vm.selectedRow = -1;
        vm.closeEarlierWeeklyPlanWindow = closeEarlierWeeklyPlanWindow;
        vm.selectEarlierWeeklyPlanWindow = selectEarlierWeeklyPlanWindow;
        vm.editDateTemplate = editDateTemplate;
        vm.loadEvents = loadEvents;
        vm.SectionLeaderApprove = SectionLeaderApprove;
        vm.daySelect = daySelect;
        vm.editDay = editDay;
        vm.del = del;
        vm.ok = ok;
        vm.back = back;
        vm.cancel = cancel;
        vm.addNew = addNew;
        vm.addBtnEnabled = true;
        vm.editBtnEnabled = false;
        vm.deleteBtnEnabled = false;
        vm.editEnabled = false;
        vm.checkedItems = [];
        vm.checkedIndices = [];
        vm.athletesChange = athletesChange;
        vm.selectAll = selectAll;
        vm.deleteSelection = deleteSelection;
        vm.chooseTraining = chooseTraining;
        vm.chooseTrainingCancel = chooseTrainingCancel;
        vm.chooseTrainingAccept = chooseTrainingAccept;
        vm.createTrainingTemplate = createTrainingTemplate;
        vm.claimAsset = claimAsset;
        vm.openTemplate = openTemplate;
        vm.reportParametersEszkozIgenyek = [];

        vm.loadEventsEnabled = false;
        vm.caBtnEnabled = false;
        vm.days = [
            {value: 1, text: gettextCatalog.getString('Monday')},
            {value: 2, text: gettextCatalog.getString('Tuesday')},
            {value: 3, text: gettextCatalog.getString('Wednesday')},
            {value: 4, text: gettextCatalog.getString('Thursday')},
            {value: 5, text: gettextCatalog.getString('Friday')},
            {value: 6, text: gettextCatalog.getString('Saturday')},
            {value: 7, text: gettextCatalog.getString('Sunday')},
        ];
        vm.tetelColumns = [
            {field: 'id', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'left',
                width: '10%'
            },
            {
                field: 'Nap',
                headerText: gettextCatalog.getString('Day'),
                textAlign: 'left',
                width: '10%'
            },
            {
                field: 'Tol',
                headerText: gettextCatalog.getString('From'),
                textAlign: 'left',
                width: '10%'
            },
            {
                field: 'Ig',
                headerText: gettextCatalog.getString('To'),
                textAlign: 'left',
                width: '10%'
            },
            {
                field: 'Hely',
                headerText: gettextCatalog.getString('Place'),
                textAlign: 'left',
                width: '20%'
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false},
            allowTextWrap: true,
            allowScrolling: true,
            allowSorting: false,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 200,
            columns: vm.tetelColumns,
            //rowSelected: vm.daySelect,
            rowSelected: function (args) {
                vm.selectedRowIndex = args.rowIndex;
                daySelect();
            },
            selectedRowIndex: vm.selectedRowIndex,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.columnsPopup = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'FelkHetSzama',
                headerText: gettextCatalog.getString('FelkHetSzam'),
                textAlign: 'center',
                width: 50,
                allowEditing: false
            },
            {
                field: 'MakrociklusSzama',
                headerText: gettextCatalog.getString('Makrociklus'),
                textAlign: 'center',
                width: 50,
                allowEditing: false
            },
            {
                field: 'Hetfo',
                headerText: gettextCatalog.getString('Monday'),
                textAlign: 'center',
                type: 'date',
                format: 'yM',
                width: 100,
                allowEditing: false
            },
            {
                field: 'Vasarnap',
                headerText: gettextCatalog.getString('Saturday'),
                textAlign: 'center',
                type: 'date',
                format: 'yM',
                width: 100,
                allowEditing: false
            }
        ];

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false, allowDeleting: false, allowAdding: false},
            allowTextWrap: true,
            allowScrolling: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 200,
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        gridPopup.appendTo('#GridPopup');
        gridPopup.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridPopup);
        };

        vm.columnsEdzesTetelek = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Tipus',
                headerText: gettextCatalog.getString('Tipus'),
                textAlign: 'center',
                width: 75
            },
            {
                field: 'GyakorlatNev',
                headerText: gettextCatalog.getString('GyakorlatNev'),
                textAlign: 'center',
                width: 75
            },
            {
                field: 'Mennyiseg',
                headerText: gettextCatalog.getString('Mennyiseg'),
                textAlign: 'center',
                width: 40
            }
        ];

        var gridEdzesTetelek = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false, allowDeleting: false, allowAdding: false},
            allowTextWrap: true,
            allowScrolling: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 200,
            columns: vm.columnsEdzesTetelek,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridEdzesTetelek'), $scope);
                }
            }
        });
        gridEdzesTetelek.appendTo('#GridEdzesTetelek');
        gridEdzesTetelek.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridEdzesTetelek);
        };

        vm.Helyek = $sce.trustAsResourceUrl('https://maps.google.it/maps?q=&output=embed');
        vm.TrainingTemplates = [];
        vm.trainingTemplate = undefined;
        vm.TrainingTemplateSelected = TrainingTemplateSelected;

        vm.edit = {};
        vm.edit.idx = -1;
        vm.edit.ac = '';
        vm.edit.Nap = '';
        vm.edit.Datum = '';
        vm.edit.Hely = '';
        vm.edit.Tol = '08:00';
        vm.edit.Ig = '17:00';
        vm.edit.Leiras = '';
        vm.edit.NapSzam = 1;
        vm.edit.Sablon = '';
        vm.edit.EdzesFejId = null;

        vm.dataEdzesTetelek = [];

        vm.tetelek = [];

        activate();

        function activate() {
            var promises = [
                paramHelper.getParams([], [
                    'weeklyTrainingPlan.edzoId',
                    'weeklyTrainingPlan.szakosztalyId',
                    'weeklyTrainingPlan.korosztalyId',
                    'weeklyTrainingPlan.ev',
                    'weeklyTrainingPlan.trainingPlanId',
                    'weeklyTrainingPlan.edzoNev',
                    'weeklyTrainingPlan.szakosztalyNev',
                    'weeklyTrainingPlan.korosztalyNev'
                ]),
                authenticationService.getRight('UJHETIEDZTERV'),
                authenticationService.getRight('MODHETIEDZTERV'),
                authenticationService.getRight('MEGTHETIEDZTERV'),
                getSystemSettings('HETIEDZTERVJOVAHAGYKOT'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('HETIEDZESTERVJOVAHAGY'),
                HetiEdzestervLegregebbiHet(),
                authenticationService.getRight('UJEDZESSABLON')
            ];
            $q.all(promises).then(function (results) {
                vm.UJHETIEDZTERV = results[1];
                vm.MODHETIEDZTERV = results[2];
                vm.MEGTHETIEDZTERV = results[3];
                vm.HETIEDZTERVJOVAHAGYKOT = results[4];
                vm.SZAKOSZTVEZ = results[5];
                vm.ADMIN = results[6];
                vm.HETIEDZESTERVJOVAHAGY = results[7];
                vm.HETIEDZTERVJOVAHAGY = vm.HETIEDZTERVJOVAHAGYKOT && (vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HETIEDZESTERVJOVAHAGY;
                vm.JOVAHAGYOTT = false;
                vm.UJEDZESSABLON = results[9];
                if (!vm.MEGTHETIEDZTERV) {
                    log.permissionError(true);
                    return;
                }
                vm.trainingPlanId = results[0]['weeklyTrainingPlan.trainingPlanId'];
                if (angular.isDefined(vm.trainingPlanId) && vm.trainingPlanId !== -1) { // VAN EDZÉS TERV
                    dataservice.weeklyTrainingPlanSelectByIdNew(vm.trainingPlanId).then(function (data) {
                        vm.editedWTP = data.retValue;
                        vm.JOVAHAGYOTT = vm.editedWTP.Jovahagyott;
                        if (vm.JOVAHAGYOTT) {
                            vm.HETIEDZTERVJOVAHAGY = false;
                        }
                        vm.editedWTP.Keszult = $filter('dateStringFilter')(vm.editedWTP.Keszult);
                        vm.tetelek = data.retValue.gridData;
                        vm.tetelek = $filter('dateStringFilter')(vm.tetelek, 'Datum');
                        grid.dataSource = vm.tetelek;
                        vm.copyEnabled = false;
                        vm.loadEventsEnabled = true;
                        vm.athletes = data.retValue.checkedItems;
                        vm.athleteListHeight = vm.athletes.length * 15;
                        $timeout(function () {
                            vm.Helyek = $sce.trustAsResourceUrl('https://maps.google.it/maps?q=&output=embed');
                            vm.edit.Hely = $sce.trustAsResourceUrl('https://maps.google.it/maps?q=&output=embed');
                            $scope.$apply();
                            vm.edit.Hely = '';
                        }, 10);
                        $timeout(function () {
                            grid.refresh();
                            if (data.retValue.mindenki === false) {
                                data.retValue.checkedIndices.forEach(function (chk) {
                                    var index = $filter('getIndex')(data.retValue.checkedItems, 'value', chk);
                                    $('#athletesList').ejListBox('checkItemByIndex', index);
                                });
                            } else {
                                selectAll();
                            }
                        }, 1000);
                    });
                } else { // NINCS EDZÉS TERV
                    vm.editedWTP.EdzoNev = results[0]['weeklyTrainingPlan.edzoNev'];
                    vm.editedWTP.SzakosztalyNev = results[0]['weeklyTrainingPlan.szakosztalyNev'];
                    vm.editedWTP.KorosztalyNev = results[0]['weeklyTrainingPlan.korosztalyNev'];
                    vm.editedWTP.Keszult = $filter('dateStringFilter')(new Date());
                    vm.editedWTP.IdEdzo = results[0]['weeklyTrainingPlan.edzoId'];
                    vm.editedWTP.IdSzakosztaly = results[0]['weeklyTrainingPlan.szakosztalyId'];
                    vm.editedWTP.IdKorosztaly = results[0]['weeklyTrainingPlan.korosztalyId'];
                    dataservice.getAthletesForWeeklyTrainingPlan(vm.editedWTP.IdEdzo, vm.editedWTP.IdKorosztaly).then(function (data) {
                        vm.athletes = data.itemsList;
                        vm.athleteListHeight = vm.athletes.length * 15;
                        $timeout(function () {
                            angular.element('#deHetfo').ejDatePicker({minDate: results[8].data});
                            selectAll();
                        }, 1000);
                    });
                }
            });
        }

        function daySelect() {
            var changed = false;
            if (vm.selectedRow >= 0 && vm.edit && vm.tetelek[vm.selectedRow] &&
                (
                    vm.edit.EdzesFejId !== vm.tetelek[vm.selectedRow].EdzesFejId ||
                    vm.edit.Datum !== vm.tetelek[vm.selectedRow].Datum ||
                    vm.edit.Hely !== vm.tetelek[vm.selectedRow].Hely ||
                    vm.edit.Ig !== vm.tetelek[vm.selectedRow].Ig ||
                    vm.edit.Tol !== vm.tetelek[vm.selectedRow].Tol ||
                    vm.edit.Leiras !== vm.tetelek[vm.selectedRow].Leiras ||
                    vm.edit.Nap !== vm.tetelek[vm.selectedRow].Nap ||
                    vm.edit.NapSzam != vm.tetelek[vm.selectedRow].NapSzam
                )
            ) {
                changed = true;
            }
            // Éppen szerkesztés közben kattint el
            if (vm.editEnabled && changed) {
                swal({
                    title: gettextCatalog.getString('DoYouWantToSaveChanges?'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('No')
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        ok();
                        vm.selectedRow = vm.selectedRowIndex;
                        $timeout(function () {
                            vm.editBtnEnabled = true;
                            vm.deleteBtnEnabled = vm.JOVAHAGYOTT === false;
                            editDay();
                            $scope.$apply();
                        }, 300);
                    } else {
                        cancel();
                        vm.selectedRow = vm.selectedRowIndex;
                        $timeout(function () {
                            vm.editBtnEnabled = true;
                            vm.deleteBtnEnabled = vm.JOVAHAGYOTT === false;
                            editDay();
                            $scope.$apply();
                        }, 300);
                    }
                });
            } else {
                $timeout(function () {
                    vm.editBtnEnabled = true;
                    vm.deleteBtnEnabled = vm.JOVAHAGYOTT === false;
                    vm.selectedRow = vm.selectedRowIndex;
                    editDay();
                    $scope.$apply();
                }, 0);
            }
        }

        function addNew() {
            if (!vm.editedWTP.Hetfo) {
                log.errorMsg(gettextCatalog.getString('ChooseMondayFirst'));
                return;
            }
            vm.edit = {};
            vm.edit.idx = -1;
            vm.edit.Helyek = [];
            vm.edit.Nap = '';
            vm.edit.Datum = '';
            vm.edit.Hely = '';
            vm.edit.Tol = '08:00';
            vm.edit.Ig = '17:00';
            vm.edit.Leiras = '';
            vm.edit.NapSzam = 1;
            vm.edit.Sablon = '';
            vm.editEnabled = true;
        }

        function editTraining(idx) {
            vm.editEnabled = true;
            vm.edit.idx = idx;
            vm.edit.Nap = vm.tetelek[idx].Nap;
            vm.edit.Datum = vm.tetelek[idx].Datum;
            vm.edit.Hely = vm.tetelek[idx].Hely;
            vm.edit.Tol = vm.tetelek[idx].Tol;
            vm.edit.Ig = vm.tetelek[idx].Ig;
            vm.edit.Leiras = vm.tetelek[idx].Leiras;
            vm.edit.NapSzam = vm.tetelek[idx].NapSzam;
            vm.edit.EdzesFejId = vm.tetelek[idx].EdzesFejId;
            vm.edit.Sablon = vm.tetelek[idx].Sablon;
            vm.edit.ac = vm.tetelek[idx].ac;
            if (vm.edit.Hely) {
                vm.Helyek = $sce.trustAsResourceUrl('https://maps.google.it/maps?q=' + vm.edit.Hely + '&output=embed');
            }
            vm.caBtnEnabled = vm.edit.EdzesFejId > 0;
            if (vm.edit.EdzesFejId > 0) {
                angular.element('#ct').ejButton({text: gettextCatalog.getString('ChangeTemplate')});
            } else {
                angular.element('#ct').ejButton({text: gettextCatalog.getString('ChooseTemplate')});
            }
        }

        function editDay() {
            $timeout(function () {
                vm.editEnabled = false;
                editTraining(vm.selectedRow);
                $scope.$apply();
            }, 0);
        }

        function del() {
            swal({
                title: gettextCatalog.getString('AreYouSureYouWantToDeleteRecord?'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.editBtnEnabled = false;
                    vm.deleteBtnEnabled = false;
                    vm.editEnabled = false;
                    vm.tetelek.splice(vm.selectedRow, 1);
                    for (var i = 0; i < vm.tetelek.length; i++) {
                        vm.tetelek[i].id = i;
                    }
                    grid.dataSource = vm.tetelek;
                    cancel();
                }
            }, function () {
            });
        }

        function cancel() {
            $timeout(function () {
                vm.editEnabled = false;
                vm.deleteBtnEnabled = false;
                grid.refresh();
                $scope.$apply();
            }, 10);
        }

        function ok() {
            vm.edit.Hely = $('#Hely').val();
            var Datum = $filter('addDays')(vm.editedWTP.Hetfo, vm.edit.NapSzam - 1);
            $timeout(function () {
                if (vm.edit.idx >= 0) {
                    vm.tetelek[vm.edit.idx].Sablon = vm.edit.Sablon;
                    vm.tetelek[vm.edit.idx].EdzesFejId = vm.edit.EdzesFejId;
                    vm.tetelek[vm.edit.idx].Hely = vm.edit.Hely;
                    vm.tetelek[vm.edit.idx].Tol = vm.edit.Tol;
                    vm.tetelek[vm.edit.idx].Ig = vm.edit.Ig;
                    vm.tetelek[vm.edit.idx].Leiras = vm.edit.Leiras;
                    vm.tetelek[vm.edit.idx].NapSzam = vm.edit.NapSzam;
                    vm.tetelek[vm.edit.idx].Nap = NumberOfDayToWeekDay(vm.edit.NapSzam);
                    vm.tetelek[vm.edit.idx].Datum = $filter('addDays')(vm.editedWTP.Hetfo, vm.edit.NapSzam - 1);
                } else {
                    var id = -1;
                    for (var i = 0; i < vm.tetelek.length; i++) {
                        if (vm.tetelek[i].id > id) {
                            id = vm.tetelek[i].id;
                        }
                    }
                    vm.tetelek.push(
                        {
                            Nap: NumberOfDayToWeekDay(vm.edit.NapSzam),
                            Datum: $filter('addDays')(vm.editedWTP.Hetfo, vm.edit.NapSzam - 1),
                            Hely: vm.edit.Hely,
                            Tol: vm.edit.Tol,
                            Ig: vm.edit.Ig,
                            Leiras: vm.edit.Leiras,
                            NapSzam: vm.edit.NapSzam,
                            EdzesFejId: vm.edit.EdzesFejId,
                            Sablon: vm.edit.Sablon,
                            id: ++id
                        }
                    );
                }
                grid.dataSource = vm.tetelek;
                vm.editEnabled = false;
                vm.deleteBtnEnabled = false;
                grid.refresh();
                $scope.$apply();
            }, 10);
        }

        function chooseTrainingCancel() {
            angular.element('#ChooseTrainingWindow').data('ejDialog').close();
            gridEdzesTetelek.dataSource = [];
        }

        function chooseTrainingAccept(arg) {
            angular.element('#ChooseTrainingWindow').data('ejDialog').close();
            vm.edit.EdzesFejId = vm.SelectedEdzesFejId;
            vm.edit.Sablon = vm.EdzesSablonNev;
            vm.edit.ac = vm.EdzesSablonHosszuNev;
            switch (arg) {
                case 'copy':
                    if (vm.EdzesTetelLeiras) {
                        if (vm.edit.Leiras && vm.edit.Leiras.length > 0) {
                            swal({
                                title: gettextCatalog.getString('AreYouSureYouWantToReplaceTrainingDescription?'),
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#DD6B55',
                                confirmButtonText: gettextCatalog.getString('Yes'),
                                cancelButtonText: gettextCatalog.getString('No')
                            }).then(function (isConfirm) {
                                if (isConfirm.value) {
                                    $timeout(function () {
                                        vm.edit.Leiras = vm.EdzesTetelLeiras;
                                    }, 10);
                                }
                            }, function () {
                            });
                        } else {
                            vm.edit.Leiras = vm.EdzesTetelLeiras;
                        }
                    }
                    break;
                case 'append':
                    if (vm.EdzesTetelLeiras) {
                        vm.edit.Leiras = vm.edit.Leiras + ', ' + vm.EdzesTetelLeiras;
                    }
                    break;
            }
        }

        function chooseTraining() {
            vm.EdzesTetelLeiras = '';
            vm.TrainingTemplates = [];
            vm.dataEdzesTetelek = [];
            dataservice.EdzesFejLista(vm.editedWTP.IdSzakosztaly, vm.editedWTP.IdKorosztaly).then(function (response) {
                vm.TrainingTemplates = response.itemsList;
                vm.trainingTemplate = '';
                if (vm.edit.ac && vm.edit.ac.length > 0) {
                    vm.trainingTemplate = vm.edit.ac;
                    TrainingTemplateSelected(vm.edit.ac, vm.edit.EdzesFejId);
                }
                angular.element('#TrainingTemplate').ejAutocomplete({
                    dataSource: vm.TrainingTemplates,
                    fields: {key: 'value', text: 'text'},
                    multiSelectMode: 'none',
                    multiColumnSettings: {
                        enable: true,
                        showHeader: true,
                        columns: [
                            {field: 'text', headerText: 'EdzesFejNeve', key: 'value'},
                            {field: 'szakosztaly', headerText: 'Szakosztaly', key: 'value'},
                            {field: 'korosztaly', headerText: 'Korosztaly', key: 'value'}
                        ]
                    },
                    showPopupButton: true,
                    showEmptyResultText: true,
                    select: function (arg) {
                        vm.dataEdzesTetelek = [];
                        TrainingTemplateSelected(arg.value, arg.item.value);
                        arg.cancel = true;
                    },
                    value: vm.trainingTemplate,
                    width: '100%'
                });
                angular.element('#ChooseTrainingWindow').data('ejDialog').open();
            });
            gridEdzesTetelek.dataSource = [];
        }

        function TrainingTemplateSelected(name, EdzesFejId) {
            vm.EdzesTetelLeiras = '';
            vm.EdzesSablonNev = '';
            vm.EdzesSablonHosszuNev = '';
            if (!(EdzesFejId > 0)) {
                return;
            }
            dataservice.EdzesSablon(EdzesFejId).then(function (response) {
                if (response.data && response.data.found) {
                    vm.SelectedEdzesFejId = response.data.EdzesFejId;
                    vm.EdzesTetelLeiras = response.data.Leiras;
                    vm.dataEdzesTetelek = response.data.Tetelek;
                    vm.EdzesSablonNev = response.data.Nev;
                    vm.EdzesSablonHosszuNev = name;
                    gridEdzesTetelek.dataSource = vm.dataEdzesTetelek;
                }
            });
        }

        function createTrainingTemplate() {
            if (!vm.UJEDZESSABLON) {
                log.permissionError();
                return;
            }
            angular.element('#ChooseTrainingWindow').data('ejDialog').close();
            swal({
                title: gettextCatalog.getString('DoYouWantToSaveChanges?'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    save(true, false);
                }
                $timeout(function () {
                    paramHelper.setParams([{'trainingPlanCreate.trainingId': -1}]);
                    $state.go('administration.trainingPlan.trainingPlanCreate');
                }, 700);
            }, function () { });
        }

        function NumberOfDayToWeekDay(napSzam) {
            // return vm.days[parseInt(napSzam)];
            switch (napSzam) {
                case 1:
                case '1':
                    return gettextCatalog.getString('Monday');
                    break;
                case 2:
                case '2':
                    return gettextCatalog.getString('Tuesday');
                    break;
                case 3:
                case '3':
                    return gettextCatalog.getString('Wednesday');
                    break;
                case 4:
                case '4':
                    return gettextCatalog.getString('Thursday');
                    break;
                case 5:
                case '5':
                    return gettextCatalog.getString('Friday');
                    break;
                case 6:
                case '6':
                    return gettextCatalog.getString('Saturday');
                    break;
                case 7:
                case '7':
                    return gettextCatalog.getString('Sunday');
                    break;
            }
        }

        function getRowIndexForDate(date) {
            for (var i = 0; i < vm.data.length; i++) {
                if (vm.data[i].Datum == date) {
                    return i;
                }
            }
            return -1;
        }

        function loadEvents() {
            if (!vm.loadEventsEnabled) {
                return;
            }
            dataservice.EsemenyekAttolteseHetiEdzestervre(vm.editedWTP.IdEdzo, vm.editedWTP.IdKorosztaly, vm.editedWTP.Hetfo).then(function () {
                grid.refresh();
            });
        }

        function save(createEvents, navigateBack) {
            createEvents = true;
            if (vm.editEnabled) {
                ok();
            }
            if (vm.UJHETIEDZTERV || vm.MODHETIEDZTERV) {
                $timeout(function () {
                    $scope.$broadcast('submitted');
                    grid.refresh();
                    if ($scope.form.$valid === false) {
                        log.errorMsg('FillTheRequiredFields');
                    } else {
                        var temp = $('#athletesList').ejListBox('getCheckedItems');
                        console.log('temp', temp);
                        vm.checkedItems = [];
                        temp.forEach(function (t) {
                            vm.checkedItems.push(t.data);
                        });
                        var mindenki = temp.length === vm.athletes.length;
                        dataservice.saveWeeklyTrainingPlanNew(vm.editedWTP, vm.tetelek, createEvents, mindenki, vm.checkedItems).then(function () {
                            log.successMsg('SavedSuccessful');
                            if (navigateBack !== false) {
                                back();
                            }
                        });
                    }
                }, 400);
            }
        }

        function openFiles() {
            paramHelper.setParams([
                {'weeklyTrainingPlan.edzoId': vm.editedWTP.IdEdzo},
                {'weeklyTrainingPlan.szakosztalyId': vm.editedWTP.IdSzakosztaly},
                {'weeklyTrainingPlan.korosztalyId': vm.editedWTP.IdKorosztaly},
                {'weeklyTrainingPlan.ev': vm.selectedYear},
                {'weeklyTrainingPlan.trainingPlanId': vm.editedWTP.Id},
                {'weeklyTrainingPlan.edzoNev': vm.editedWTP.EdzoNev},
                {'weeklyTrainingPlan.szakosztalyNev': vm.editedWTP.SzakosztalyNev},
                {'weeklyTrainingPlan.korosztalyNev': vm.editedWTP.KorosztalyNev},
                {'files.tipus': 'EdzesTervHeti'},
                {'files.tablaId': vm.editedWTP.Id}
            ]);
            $state.go('other.files');
        }

        function showPrint() {
            var tetelek = $.extend(true, [], vm.tetelek);
            vm.reportParameters = {
                id: vm.editedWTP.Id,
                szakosztaly: vm.editedWTP.SzakosztalyNev,
                korosztaly: vm.editedWTP.KorosztalyNev,
                edzo: vm.editedWTP.EdzoNev,
                keszult: vm.editedWTP.Keszult,
                makrociklus: vm.editedWTP.Makrociklus,
                hetfo: vm.editedWTP.Hetfo,
                vasarnap: vm.editedWTP.Vasarnap,
                felkhet: vm.editedWTP.HetSzama,
                tetelek: tetelek
            };
            $timeout(function () {
                if (printOpened) {
                    angular.element('#printDialog').ejDialog('open');
                } else {
                    printOpened = true;
                    angular.element('#printDialog').ejDialog({
                        height: '99%',
                        width: '99%',
                        enablemodal: 'true',
                        'position-x': '0',
                        'position-y': '1',
                        target: '#mainContent',
                        enableresize: 'false'
                    });
                }
            }, 500);
        }

        function MondayChange() {
            var sDate = angular.element('#deHetfo').data('ejDatePicker').getValue();
            var sMonday = $filter('startOfWeek')(sDate);
            var sSunday = $filter('endOfWeek')(sDate);
            angular.element('#deHetfo').ejDatePicker({value: sMonday});
            vm.editedWTP.Hetfo = sMonday;
            vm.editedWTP.Vasarnap = sSunday;
            vm.loadEventsEnabled = true;
            if (vm.editedWTP.HetSzama === undefined || vm.editedWTP.HetSzama == null || vm.editedWTP.HetSzama === '' || vm.editedWTP.HetSzama == 0 || vm.editedWTP.HetSzama == '0') {
                vm.editedWTP.HetSzama = $filter('stringToMoment')(sDate).week();
            }
            $timeout(function () {
                for (var i = 0; i < vm.tetelek.length; i++) {
                    vm.tetelek[i].Datum = $filter('addDays')(sMonday, vm.tetelek[i].NapSzam - 1);
                }
                grid.refresh();
                $scope.$apply();
                grid.dataSource = vm.tetelek;
            }, 100);
            if (vm.trainingPlanId == -1) {
                vm.copyEnabled = true;
            }
        }

        function copyFromOldPlan() {
            if (vm.trainingPlanId > 0) {
                log.infoMsg(gettextCatalog.getString('FormerWeeklyTrainingPlanCanOnlyBeCopiedToNewPlans'));
                return;
            }
            if (!vm.copyEnabled) {
                log.infoMsg(gettextCatalog.getString('ChooseMondayFirst'));
                return;
            }
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();
            dataservice.getOldWeeklyPlanByUserId(vm.editedWTP.IdEdzo, vm.editedWTP.Id).then(function (args) {
                gridPopup.dataSource = args.itemsList;
            });
        }

        function closeEarlierWeeklyPlanWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectEarlierWeeklyPlanWindow() {
            var oldPlan = $rootScope.getSelectedItems(gridPopup)[0];
            if (oldPlan) {
                dataservice.weeklyTrainingPlanSelectByIdNew(oldPlan.Id).then(function (data) {
                    vm.tetelek = [];
                    for (var i = 0; i < data.retValue.gridData.length; i++) {
                        var NapSzam = 1;
                        switch (data.retValue.gridData[i].Nap) {
                            case gettextCatalog.getString('Monday'):
                                NapSzam = 1;
                                break;
                            case gettextCatalog.getString('Tuesday'):
                                NapSzam = 2;
                                break;
                            case gettextCatalog.getString('Wednesday'):
                                NapSzam = 3;
                                break;
                            case gettextCatalog.getString('Thursday'):
                                NapSzam = 4;
                                break;
                            case gettextCatalog.getString('Friday'):
                                NapSzam = 5;
                                break;
                            case gettextCatalog.getString('Saturday'):
                                NapSzam = 6;
                                break;
                            case gettextCatalog.getString('Sunday'):
                                NapSzam = 7;
                                break;
                        }
                        data.retValue.gridData[i].Datum = $filter('addDays')(vm.editedWTP.Hetfo, NapSzam - 1);
                        vm.tetelek.push(data.retValue.gridData[i]);
                    }
                    grid.dataSource = vm.tetelek;
                });
                var dialogObj = angular.element('#searchWindow').data('ejDialog');
                dialogObj.close();
            }
        }

        function editDateTemplate() {
            return {
                create: function () {
                    return '<input type="text">';
                },
                read: function (args) {
                    return args[0].value;
                },
                write: function (args) {
                    var s = args.element[0].id.toString().replace('Grid', '');
                    var q = args.element.ejTimePicker({
                        value: args.rowdata !== undefined ? args.rowdata[s] : '',
                        cssClass: 'gridEditTemplateTextAndButton',
                        timeFormat: 'HH:mm'
                    });
                    vm.editedRowId = args.rowdata['Id'];
                    $compile(args.element.context[1])($scope);
                    return q;
                }
            };
        }

        function athletesChange(args) {
            if (vm.JOVAHAGYOTT) {
                args.cancel = true;
                return;
            }
            if (args.isChecked) {
                vm.checkedItems.push({value: args.value, text: args.text});
            } else {
                vm.checkedItems.splice(vm.checkedItems.indexOf({value: args.value, text: args.text}), 1);
            }
        }

        function selectAll() {
            $('#athletesList').ejListBox('checkAll');
            vm.checkedItems = $.extend(true, [], vm.athletes);
        }

        function deleteSelection() {
            $('#athletesList').ejListBox('uncheckAll');
            vm.checkedItems = [];
        }

        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }

        function SectionLeaderApprove() {
            dataservice.HetiEdzesTervJovahagyas(vm.editedWTP.Id).then(function () {
                log.successMsg(gettextCatalog.getString('WeeklyTraningPlanApproved'));
                vm.HETIEDZTERVJOVAHAGY = false;
                vm.JOVAHAGYOTT = true;
                $rootScope.back();
            });
        }

        function claimAsset() {
            dataservice.EszkozIgenyLista(vm.edit.EdzesFejId).then(function (response) {
                var neededAssets = [];
                for (var i = 0; i < response.data.length; i++) {
                    neededAssets.push({id: 'asset' + i, text: response.data[i], value: 1});
                }
                var HTMLtext = createHtmlSwalContent(neededAssets);
                swal({
                    title: gettextCatalog.getString('PrintAssetClaim'),
                    html: HTMLtext,
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        var neededAssetParams = [];
                        for (var i = 0; i < response.data.length; i++) {
                            var val = document.getElementById('asset' + i).value;
                            if (val) {
                                neededAssetParams.push({name: response.data[i], value: val});
                            }
                        }
                        showPrint2(neededAssetParams);
                    }
                }, function () {
                });
            });
        }

        function showPrint2(arg) {
            var items = $.extend(true, [], arg);
            vm.reportParameters2 = {
                edzo: vm.editedWTP.EdzoNev,
                szakosztaly: vm.editedWTP.SzakosztalyNev,
                korosztaly: vm.editedWTP.KorosztalyNev,
                datum: vm.edit.Datum,
                elemek: items
            };
            $timeout(function () {
                if (printOpened2) {
                    angular.element('#printDialog2').ejDialog('open');
                } else {
                    printOpened2 = true;
                    angular.element('#printDialog2').ejDialog({
                        height: '99%',
                        width: '99%',
                        enablemodal: 'true',
                        'position-x': '0',
                        'position-y': '1',
                        target: '#mainContent',
                        enableresize: 'false'
                    });
                }
            }, 500);
        }

        function createHtmlSwalContent(arr) {
            var HTMLtext = '<div style="margin-top: 10px;" >';
            arr.forEach(function (item) {
                HTMLtext += '<div class="row">';
                if (item.hasOwnProperty('cb')) {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:7px;">' + item.text + '</label>';
                    HTMLtext += '<div style="float:left;">';
                    HTMLtext += '<div class="col-lg-6"><input id="' + item.id + '" ej-checkbox></input></div><br>';
                    HTMLtext += '</div>';
                } else if (item.hasOwnProperty('cp')) {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:7px;">' + item.text + '</label>';
                    HTMLtext += '<div class="col-lg-6"><input id="' + item.id + '" ej-colorpicker></input></div><br>';
                } else if (item.hasOwnProperty('ac')) {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:7px;">' + item.text + '</label>';
                    HTMLtext += '<div class="col-lg-6"><input id="AutoCompleteSwal" ej-autocomplete></input></div><br>';
                } else if (item.hasOwnProperty('dd')) {
                    HTMLtext += '<br>';
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:7px;">' + item.text + '</label>';
                    HTMLtext += '<div class="col-lg-6"><input id="' + item.id + '" ej-dropdownlist e-width="100%"></input></div><br>';
                } else {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:7px;">' + item.text + '</label>';
                    if (item.value && item.value.length > 0) {
                        HTMLtext += '<div class="col-lg-6"><input type="text" value="' + item.value + '" id="' + item.id + '"></input></div><br>';
                    } else {
                        HTMLtext += '<div class="col-lg-6"><input type="text" value="" id="' + item.id + '"></input></div><br>';
                    }
                }
                HTMLtext += '</div>';
            });
            HTMLtext += '</div>';
            return HTMLtext;
        }

        function openTemplate() {
            paramHelper.setParams([
                {'trainingPlanCreate.trainingId': vm.edit.EdzesFejId}
            ]);
            $state.go('administration.trainingPlan.trainingPlanTemplate');
        }

        function back() {
            $rootScope.back();
        }

        function HetiEdzestervLegregebbiHet() {
            return dataservice.HetiEdzestervLegregebbiHet().then(function (response) {
                return response;
            });
        }
    }
})();
