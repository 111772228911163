(function () {
    'use strict';

    angular
        .module('app.reports.weeklyTravelReport')
        .controller('WeeklyTravelReportController', WeeklyTravelReportController);

    function WeeklyTravelReportController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, paramHelper, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('WeeklyTravelReport');

        vm.typeList = [
            {value: 1, text: gettextCatalog.getString('Plan')},
            {value: 2, text: gettextCatalog.getString('Fact')},
            {value: 3, text: gettextCatalog.getString('All')}
        ];

        vm.typeId = 3;
        vm.sectionList = [];
        vm.sectionId = 0;
        vm.ageGroupList = [];
        vm.ageGroupId = 0;
        vm.coachList = [];
        vm.coachId = 0;
        vm.datasource = [];
        vm.onlyStartDate = false;
        vm.selectdateFrom = getMonday(new Date(), 0);
        vm.selectdateTo = getMonday(new Date(), 6);
        vm.showOrder = 1;
        vm.typeDataBound = typeDataBound;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.busOwnersDataBound = busOwnersDataBound;
        vm.busOwnerSelect = busOwnerSelect;
        vm.typeSelect = typeSelect;
        vm.coachSelect = coachSelect;
        vm.show = show;
        vm.radioButtonChecked = radioButtonChecked;
        vm.openPlan = openPlan;
        vm.busOwners = [];
        vm.busOwnerId = '-1';
        vm.fromDateChange = fromDateChange;
        vm.toDateChange = toDateChange;

        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'Link', headerText: '', template: '#WTRPlanOpenTemplate', textAlign: 'center', width: 60},
            {field: 'VersenySorszam', headerText: gettextCatalog.getString('VersenySorszam'), width: 120, textAlign: 'center'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('SzakosztalyNeve'), width: 150, textAlign: 'center'},
            {field: 'VersenyNeve', headerText: gettextCatalog.getString('VersenyNeve'), width: 150, textAlign: 'center'},
            {field: 'VersenyHelye', headerText: gettextCatalog.getString('VersenyHelye'), width: 150, textAlign: 'center'},
            {field: 'SzallitoJarmuTipusa', headerText: gettextCatalog.getString('JarmuTipus'), width: 150, textAlign: 'center'},
            {field: 'SzallitoJarmuTulajdonos', headerText: gettextCatalog.getString('JarmuTulajdonos'), width: 150, textAlign: 'center'},
            {field: 'JarmuKiallasIdopontja', headerText: gettextCatalog.getString('JarmuKiallasIdopontja'), width: 150, textAlign: 'center'},
            {field: 'JarmuKiallasHelye', headerText: gettextCatalog.getString('JarmuKiallasHelye'), width: 150, textAlign: 'center'},
            {field: 'VisszaindulasIdopontja', headerText: gettextCatalog.getString('VisszaindulasIdopontja'), width: 150, textAlign: 'center'},
            {field: 'FelelosEdzoNeve', headerText: gettextCatalog.getString('FelelosEdzoNeve'), width: 150, textAlign: 'center'},
            {field: 'EdzoElerhetosege', headerText: gettextCatalog.getString('EdzoElerhetosege'), width: 150, textAlign: 'center'},
            {field: 'Vegleges', headerText: gettextCatalog.getString('Final'), displayAsCheckBox: true, width: 150, textAlign: 'center'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function getMonday(d, plusDay) {
            d = new Date(d);
            var day = d.getDay();
            var diff = d.getDate() - day + (day == 0 ? -6 : 1) + plusDay; // adjust when day is sunday
            return new Date(d.setDate(diff));
        }

        activate();

        function activate() {
            authenticationService.getRight('UTAZTERVMEGT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getBusOwners();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'weeklyTrainingPlans.sectionId',
                'weeklyTrainingPlans.ageGroupId',
                'weeklyTrainingPlans.coachId',
                'weeklyTrainingPlans.onlyStartDate',
                'weeklyTrainingPlans.typeId',
                'weeklyTrainingPlans.busOwnerId'
            ])];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['weeklyTrainingPlans.sectionId'] ? results[0]['weeklyTrainingPlans.sectionId'] : vm.sectionId;
                vm.ageGroupId = results[0]['weeklyTrainingPlans.ageGroupId'] ? results[0]['weeklyTrainingPlans.ageGroupId'] : vm.ageGroupId;
                vm.coachId = results[0]['weeklyTrainingPlans.coachId'] ? results[0]['weeklyTrainingPlans.coachId'] : vm.coachId;
                vm.onlyStartDate = results[0]['weeklyTrainingPlans.onlyStartDate'] != null ? results[0]['weeklyTrainingPlans.onlyStartDate'] : vm.onlyStartDate;
                vm.typeId = results[0]['weeklyTrainingPlans.typeId'] ? results[0]['weeklyTrainingPlans.typeId'] : vm.typeId;
                vm.busOwnerId = results[0]['weeklyTrainingPlans.busOwnerId'] ? results[0]['weeklyTrainingPlans.busOwnerId'] : vm.busOwnerId;
                angular.element('#onlyStartDate').ejCheckBox({checked: vm.onlyStartDate});
            });
        }

        function getBusOwners() {
            dataservice.IdegenKoltsegTervBuszTulaj().then(function (response) {
                vm.busOwners = [{value: '-1', text: ''}];
                for (var i = 0; i < response.data.length; i++) {
                    vm.busOwners.push({value: response.data[i], text: response.data[i]});
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups(args.value);
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (vm.ageGroupId) {
                    getActualCoachesList(0);
                }
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(ageGroupId) {
            return dataservice.coachDropDownList(vm.sectionId, ageGroupId, vm.selectdateFrom, vm.selectdateTo).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age coach selectedplayUpAndDown
        function coachSelect(args) {
            vm.coachId = args.value;
        }
        function typeDataBound() {
            angular.element('#typeDropDown').ejDropDownList('selectItemByValue', vm.typeId);
        }

        function typeSelect(args) {
            vm.typeId = args.value;
        }

        function busOwnersDataBound() {
            if (vm.busOwners.length === 1) {
                angular.element('#BusOwnerDropDown').ejDropDownList('selectItemByValue', vm.busOwners[0].value);
            } else {
                angular.element('#BusOwnerDropDown').ejDropDownList('selectItemByValue', vm.busOwnerId);
            }
        }

        function busOwnerSelect(args) {
            vm.busOwnerId = args.value;
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getWeeklyTravelList(vm.sectionId, vm.ageGroupId, vm.coachId, vm.selectdateFrom, vm.selectdateTo, vm.onlyStartDate, vm.typeId, vm.busOwnerId).then(function (data) {
                grid.dataSource = data.itemsList;
            });
            paramHelper.setParams(
                [
                    {'weeklyTrainingPlans.sectionId': vm.sectionId},
                    {'weeklyTrainingPlans.ageGroupId': vm.ageGroupId},
                    {'weeklyTrainingPlans.coachId': vm.coachId},
                    {'weeklyTrainingPlans.onlyStartDate': vm.onlyStartDate},
                    {'weeklyTrainingPlans.typeId': vm.typeId},
                    {'weeklyTrainingPlans.busOwnerId': vm.busOwnerId}
                ]);
        }

        function radioButtonChecked(args) {
            vm.showOrder = args.model.value;
        }

        function openPlan(Id) {
            $q.all([authenticationService.getRight('MEGTIDEGENKOLTSTERV')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'awayBudgetPlan.planId': Id}]);
                    $state.go('administration.awayBudgetPlan.awayBudgetPlanCreate');
                } else {
                    log.permissionError();
                }
            });
        }

        function fromDateChange(args) {
            vm.selectdateFrom = args.value;
            getActualCoachesList(0);
        }

        function toDateChange(args) {
            vm.selectdateTo = args.value;
            getActualCoachesList(0);
        }
    }
})();
