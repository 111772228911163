(function () {
    'use strict';

    angular
        .module('app.administration.test.testList')
        .controller('TestListController', TestListController);

    function TestListController(log, dataservice, authenticationService, $q, $state, $scope, $rootScope, gettextCatalog, paramHelper) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('TestList');

        vm.testDates = [];
        vm.testDateValue = 0;

        // Publikus funkciók
        vm.testDatesDataBound = testDatesDataBound;
        vm.testDatesSelect = testDatesSelect;
        vm.newTest = newTest;
        vm.getTest = getTest;

        activate();

        function activate() {
            authenticationService.getRight('MEGTKPFELM').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([authenticationService.getRight('MODKPFELM'),
                            authenticationService.getRight('UJKPFELM'),
                            authenticationService.getRight('MEGTKPFELM'),
                            paramHelper.getParams([], ['test.testId']), getTestYears()]).then(function(results) {
                        vm.MODKPFELM = results[0];
                        vm.UJKPFELM = results[1];
                        vm.MEGTKPFELM = results[2];
                        vm.testDateValue = results[3]['test.testId'];
                    });
                }
            });
        }

        function getTestYears() {
            dataservice.getTestYears().then(function(data) {
                vm.testDates = data.itemsList;
            });
        }

        // Run after data bounded
        function testDatesDataBound() {
            if (vm.testDates.length === 1) {
                vm.testDateValue = vm.testDates[0].value;
                angular.element('#testDates').ejDropDownList('selectItemByValue', vm.testDates[0].value);
            } else {
                angular.element('#testDates').ejDropDownList('selectItemByValue', vm.testDateValue);
            }
        }

        // Run after section selected
        function testDatesSelect(args) {
            vm.testDateValue = args.value;
        }

        function newTest() {
            if (vm.UJKPFELM !== true) {
                log.permissionError();
            } else {
                // dataservice.canNewTestAthletesList(new Date().toLocaleDateString()).then(function(data) {
                //     if (data.retValue === true) {
                //         $state.go('administration.test.newTest');
                //     }
                // });
                $state.go('administration.test.newTest');
            }
        }

        function getTest() {
            if (vm.MODKPFELM !== true && vm.MEGTKPFELM !== true) {
                log.permissionError();
            } else {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                } else {
                    paramHelper.setParams([{'test.testId': vm.testDateValue}]);
                    $state.go('administration.test.testResults');
                }
            }
        }
    }
})();
