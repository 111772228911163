(function () {
    'use strict';

    angular
        .module('app.reports.centralReport.centralReportAllIn')
        .controller('CentralReportAllInController', CentralReportAllInController);

    function CentralReportAllInController(dataservice, $q, log, paramHelper, authenticationService, $rootScope, gettextCatalog, $scope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CentralReportAllIn');
        vm.years = [];
        vm.yearsBirth = dataservice.range(1960, new Date().getFullYear());
        vm.sexList = [
            {text: gettextCatalog.getString('All'), value: 'allgender'},
            {text: gettextCatalog.getString('Male'), value: 'male'},
            {text: gettextCatalog.getString('Female'), value: 'female'}
        ];
        vm.sectionList = [];
        vm.columns = [
            {
                textAlign: 'center', columns: [
                {
                    field: 'EvFelmeresi',
                    headerText: gettextCatalog.getString('TestYear'),
                    textAlign: 'left',
                    width: 120
                }, {
                    field: 'TestResztvevo',
                    headerText: gettextCatalog.getString('Participants'),
                    textAlign: 'left',
                    width: 120
                }]
            }, {
                headerText: gettextCatalog.getString('Height'), textAlign: 'center', columns: [
                    //column: 'TestOsszAtlag, TestAtlag',
                    {
                        field: 'TestOsszAtlag',
                        headerText: gettextCatalog.getString('AverageHeightOfAthlete'),
                        textAlign: 'left',
                        width: 120
                    }, {
                        field: 'TestAtlag',
                        headerText: gettextCatalog.getString('AverageRank'),
                        textAlign: 'left',
                        width: 120
                    }]
            }, {
                headerText: gettextCatalog.getString('VerticalJump'), textAlign: 'center', columns: [
                    // column: 'SulypontOsszAtlag, SulypontAtlag',
                    {
                        field: 'SulypontOsszAtlag',
                        headerText: gettextCatalog.getString('AverageVerticalJumpOfAthlete'),
                        textAlign: 'left',
                        width: 120
                    }, {
                        field: 'SulypontAtlag',
                        headerText: gettextCatalog.getString('AverageRank'),
                        textAlign: 'left',
                        width: 120
                    }]
            }, {
                headerText: gettextCatalog.getString('30mSprint'), textAlign: 'center', columns: [
                    // column: 'SprintOsszAtlag, SprintAtlag',
                    {
                        field: 'SprintOsszAtlag',
                        headerText: gettextCatalog.getString('AverageSprintAthlete'),
                        textAlign: 'left',
                        width: 120
                    }, {
                        field: 'SprintAtlag',
                        headerText: gettextCatalog.getString('AverageRank'),
                        textAlign: 'left',
                        width: 120
                    }]
            }, {
                headerText: gettextCatalog.getString('2800mRunning'), textAlign: 'center', columns: [
                    // column: 'MeterOsszAtlagStr, MeterAtlagStr',
                    {
                        field: 'MeterOsszAtlagStr',
                        headerText: gettextCatalog.getString('Average2800mRunning'),
                        textAlign: 'left',
                        width: 120
                    }, {
                        field: 'MeterAtlagStr',
                        headerText: gettextCatalog.getString('AverageRank'),
                        textAlign: 'left',
                        width: 120
                    }]
            }];
        vm.summaryRows = [{
            title: gettextCatalog.getString('Average'),
            columns: [
                {
                    type: 'average',
                    field: 'TestResztvevo',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'TestOsszAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'TestAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'SulypontOsszAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'SulypontAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'SprintOsszAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }, {
                    type: 'average',
                    field: 'SprintAtlag',
                    footerTemplate: '${average}',
                    format: 'N0'
                }]
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.clearYear = clearYear;
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.coachSelect = coachSelect;
        vm.coachDataBound = coachDataBound;
        vm.getData = getData;
        vm.sexSelect = sexSelect;
        vm.birthDateChange = birthDateChange;
        vm.selectedBirthDate = 0;
        vm.selectedAgeGroup = 0;
        vm.selectedCoach = 0;
        vm.selectedSection = 0;
        vm.coachList = [];
        vm.ageGroupList = [];

        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTKPFELM')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getFelmerokDatumaiDropDownList();
                    setDropDowns();
                }
            })
                .catch(function () {
                    log.permissionError(true);
                });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'centralReportAllIn.sectionId',
                'centralReportAllIn.ageGroupId',
                'centralReportAllIn.coachId',
                'centralReportAllIn.birthDate',
                'centralReportAllIn.sex'
            ])
            ];
            $q.all(promises).then(function (results) {
                $timeout(function () {
                    vm.selectedSection = results[0]['centralReportAllIn.sectionId'];
                    vm.selectedAgeGroup = results[0]['centralReportAllIn.ageGroupId'];
                    vm.selectedCoach = results[0]['centralReportAllIn.coachId'];
                    vm.selectedBirthDate = results[0]['centralReportAllIn.birthDate'];
                    angular.element('#gender').ejDropDownList('selectItemByValue', results[0]['centralReportAllIn.sex']);
                }, 0);
            });
        }

        function clearYear() {
            vm.selectedBirthDate = undefined;
            $('#BirthDate').ejDropDownList('clearText');
        }

        function getData() {
            paramHelper.setParams([
                {'centralReportAllIn.sectionId': vm.selectedSection},
                {'centralReportAllIn.ageGroupId': vm.selectedAgeGroup},
                {'centralReportAllIn.coachId': vm.selectedCoach},
                {'centralReportAllIn.birthDate': vm.selectedBirthDate},
                {'centralReportAllIn.sex': vm.sex},
            ]);
            dataservice.getCentralReportAllIn(vm.selectedSection, vm.selectedAgeGroup, vm.selectedCoach, vm.selectedBirthDate, vm.sex)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function sectionSelect(data) {
            vm.selectedSection = data.value;
            getAgeGroups(data.value);
            getCoaches(data.value, 0);
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function ageGroupSelect(args) {
            vm.selectedAgeGroup = args.value;
            getCoaches(vm.selectedSection, args.value);
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
                if (vm.selectedAgeGroup) {
                    getCoaches(vm.selectedSection, 0);
                }
            }
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }

        function coachSelect(args) {
            vm.selectedCoach = args.value;
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    vm.sectionList = data.itemsList;
                });
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.ageGroupList.splice(0, 0, all);
                    }
                });
        }

        function getCoaches(sectionId, ageGroupId) {
            if (!ageGroupId || ageGroupId == -1) {
                ageGroupId = 0;
            }
            return dataservice.coachDropDownList(sectionId, ageGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function getFelmerokDatumaiDropDownList() {
            dataservice.getFelmerokDatumaiDropDownList()
                .then(function (data) {
                    vm.years = data.itemsList;
                });
        }

        function sexSelect(args) {
            vm.sex = args.value;
        }

        function birthDateChange(args) {
            vm.selectedBirthDate = args.value;
        }
    }
})();

