(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.userSettings')
        .controller('userSettingsController', userSettingsController);

    function userSettingsController($q, log, VIEW_DATA, dataservice, DOMAIN, $timeout, authenticationService, $rootScope, AUTH_EVENTS, $scope, gettextCatalog, paramHelper, $state, $filter) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('UserSettings');

        // Dropdowns
        vm.sectionLeaderChangeEnable = false;
        vm.ageGroupList = [];
        vm.weightList = dataservice.range(VIEW_DATA.dropDownList.weight.downThreshold, VIEW_DATA.dropDownList.weight.upThreshold);
        vm.heightList = dataservice.range(VIEW_DATA.dropDownList.centimetre.downThreshold, VIEW_DATA.dropDownList.centimetre.upThreshold);

        // Default variables
        vm.noImg = DOMAIN.baseURL + DOMAIN.userImage;
        vm.photoUrl = vm.noImg;
        vm.passwordAgain = undefined;
        vm.recordPerPageList = $rootScope.gridPageSizes;
        vm.formData = {
            id: undefined,
            name: undefined,
            email: false,
            password: undefined,
            fileData: null,
            fileName: null,
            contact: undefined,
            country: undefined,
            city: undefined,
            birthDate: undefined,
            citizenship: undefined,
            mothersName: undefined,
            height: undefined,
            weight: undefined,
            otherInformation: undefined,
            gridRecordPerPage: undefined
        };
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.changeHeight = changeHeight;
        vm.changeWeight = changeWeight;
        vm.closeCropDialog = closeCropDialog;
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.sectionLeader = false;

        vm.selectedItems = [0, 1];
        vm.saveUser = saveUser;
        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.types = [];
        vm.recordPerPageSelect = recordPerPageSelect;

        vm.deleteImg = deleteImg;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('SAJATADATMODOSIT'),
                authenticationService.getRight('MODFELH')]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.LIMITEDUSER = !results[1];
                    if (results[1] !== true) {
                        document.getElementById('generalFieldset').disabled = true;
                        document.getElementById('personalFieldset').disabled = true;
                    }
                    vm.userId = authenticationService.getAuthData().id;
                    vm.modifyPermission = results[0];
                    getUser(vm.userId);
                }
            });
        }

        function getUser(userId) {
            return dataservice.getUserDataMin(userId)
                .then(function (data) {
                    angular.element('#sex').ejDropDownList('selectItemByValue', data.retValue.ferfib);
                    data.retValue.birthDate = $filter('datetimeFilter')(data.retValue.birthDate);
                    vm.formData = data.retValue;

                    if (vm.formData.fileName && vm.formData.fileName != 'no_image.png') {
                        vm.photoUrl = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl + vm.formData.fileName;
                    } else {
                        vm.photoUrl = vm.noImg;
                    }
                    vm.sectionLeaderChangeEnable = true;

                    angular.element('#GridRowNB').ejDropDownList('selectItemByValue', vm.formData.gridRecordPerPage);
                    $timeout(function () {
                        $scope.$apply();
                    }, 100);
                });
        }

        function recordPerPageSelect(args) {
            vm.formData.gridRecordPerPage = args.value;
        }

        function changeHeight(args) {
            vm.formData.height = args.value;
        }

        function changeWeight(args) {
            vm.formData.weight = args.value;
        }

        function saveUser() {
            if (vm.modifyPermission) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false && !vm.LIMITEDUSER) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'), gettextCatalog.getString('FillTheRequiredFields'));
                } else {
                    if (vm.formData.password != '') {
                        if (vm.formData.password != vm.passwordAgain) {
                            log.errorMsg(gettextCatalog.getString('PasswordsDontMatch'));
                            return;
                        }
                    }

                    // kép küldése, ha választott
                    if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                        vm.formData.fileData = vm.cropper.croppedImage;
                        vm.formData.fileName = angular.element('#fileName').val();
                    }
                    dataservice.modifyUserMin(vm.formData).then(function (data) {
                        if (data) {
                            var currentId = parseInt(authenticationService.getAuthData().id);
                            if (currentId === vm.formData.id) {
                                $rootScope.$broadcast(AUTH_EVENTS.refreshProfilePic, data.image);
                            }
                            paramHelper.setParams([{'gridRecordPerPage': vm.formData.gridRecordPerPage}]);
                            $rootScope.pageSettings.pageSize = parseInt(vm.formData.gridRecordPerPage);
                            log.successMsg('UserModified');
                            $rootScope.back();
                        }
                    });
                }
            } else {
                log.permissionError();
            }
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheProfileImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.formData.fileName = 'oldDeleted';
                    vm.photoUrl = vm.noImg;
                    $scope.$apply();
                }
            });
        }
    }
})();
