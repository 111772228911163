(function () {
    'use strict';

    angular
        .module('app.messageCenter.newMessage')
        .controller('NewMessageController', NewMessageController);

    function NewMessageController($q, logger, $rootScope, gettextCatalog, log, $state, dataservice, $scope, paramHelper, $timeout, authenticationService) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('NewMessage');

        vm.toolbarClick = toolbarClick;
        vm.Message = '';
        vm.LIMITEDUSER = false;
        vm.SPORTOLOUSER = false;
        vm.SZULOUSER = false;

        vm.getUsersUserGroup = getUsersUserGroup;

        vm.RecipientsIds = [];
        vm.RecipientsNames = '';
        vm.RecipientsNamesArr = [];
        vm.recipientsText = gettextCatalog.getString('recipients');
        vm.clearText = gettextCatalog.getString('clear');
        vm.showRecipientsText = gettextCatalog.getString('showRecipients');

        vm.usersList = [];
        vm.athletesList = [];
        vm.parentsList = [];
        vm.usersGroupList = [];
        vm.sectionsList = [];
        vm.teamsAthletesList = [];
        vm.teamParentsList = [];
        vm.ageGroupsList = [];
        vm.ageGroupsParentsList = [];

        vm.usersListAll = [];
        vm.athletesListAll = [];
        vm.parentsListAll = [];
        vm.usersGroupListAll = [];
        vm.sectionsListAll = [];
        vm.teamsAthletesListAll = [];
        vm.teamParentsListAll = [];
        vm.ageGroupsListAll = [];
        vm.ageGroupsParentsListAll = [];

        activate();

        let recipientsList = [];
        let recipients = new Set();
        let MultiSelect = new ej2.dropdowns.MultiSelect({
            dataSource: vm.recipientsList,
            fields: {groupBy: 'group', value: 'usersof', text: 'text'},
            mode: 'CheckBox',
            placeholder: gettextCatalog.getString('recipients'),
            showDropDownIcon: true,
            filterBarPlaceholder: gettextCatalog.getString('Search')
        });
        MultiSelect.appendTo('#recipients');

        function toolbarClick(params) {
            switch (params.currentTarget.id) {
                case '1':
                    SendMessage();
                    break;
                case '2':
                    $state.go('messageCenter.messageCenter');
                    break;
            }
        }

        function SendMessage() {
            recipients = MultiSelect.checkBoxSelectionModule.activeLi;

            var hiba = '';
            if (recipients.length === 0) {
                hiba += 'Legalább egy címzettet adjon meg!';
            }
            if (vm.Message.length === 0) {
                hiba += '<br>Adjon meg üzenetet!';
            }
            if (hiba.length > 0) {
                log.errorMsg(hiba);
                return;
            }
            vm.Obj = {};
            vm.Obj.Ids = [];
            recipients.forEach((element) => {
                vm.Obj.Ids.push(element.dataset.value);
            });
            vm.Obj.Message = vm.Message;

            dataservice.SikerMobileFreeSendNewMessage(vm.Obj).then(function () {
                log.successMsg('SendSuccessful');
                $state.go('messageCenter.messageCenter');
            });
        }

        function getUserId() {
            getUsersUserGroup(authenticationService.getAuthData().id);
        }

        function getUsersUserGroup(userid) {
            dataservice.UsersUserGroup(userid)
                .then(function (data) {
                    vm.LIMITEDUSER = false;
                    for (var i = 0; i < data.data.length; i++) {
                        switch (data.data[i]) {
                            case 'SZULO':
                                vm.SZULOUSER = true;
                                vm.LIMITEDUSER = true;
                                break;
                            case 'SPORTOLO':
                                vm.SPORTOLOUSER = true;
                                vm.LIMITEDUSER = true;
                                break;
                        }
                    }
                    return data.data;
                });
        }

        let UsersListView = new ej2.lists.ListView({
            dataSource: vm.usersList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let AthletesListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let ParentsListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let UserGroupsListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let SectionsCoachesListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let TeamsAthletesListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let TeamsAthletesParentsListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let AgegroupsAthletesListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });
        let AgegroupsAthletesParentsListView = new ej2.lists.ListView({
            dataSource: vm.athletesList,
            showCheckBox: true,
            fields: {text: 'text', id: 'value'}
        });

        let Tabs = new ej2.navigations.Tab({
            items: [
                {
                    header: {text: gettextCatalog.getString('Users')},
                    content: '<div id="ListUsers"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Athletes')},
                    content: '<div id="ListAthletes"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Parents')},
                    content: '<div id="ListParents"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('UserGroups')},
                    content: '<div id="ListUserGroups"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('SectionsCoaches')},
                    content: '<div id="ListSectionsCoaches"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Team\'s athletes')},
                    content: '<div id="ListTeamsAthletes"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Team\'s athletes parents')},
                    content: '<div id="ListTeamsAthletesParents"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Agegroup\'s athletes')},
                    content: '<div id="ListAgegroupsAthletes"></div>'
                },
                {
                    header: {text: gettextCatalog.getString('Agegroup\'s athletes parents')},
                    content: '<div id="ListAgegroupsAthletesParents"></div>'
                }
            ],
            overflowMode: 'Scrollable',
            heightAdjustMode: 'Auto',
        });
        Tabs.appendTo('#Tabs');
        UsersListView.appendTo('#ListUsers');
        AthletesListView.appendTo('#ListAthletes');
        ParentsListView.appendTo('#ListParents');
        UserGroupsListView.appendTo('#ListUserGroups');
        SectionsCoachesListView.appendTo('#ListSectionsCoaches');
        TeamsAthletesListView.appendTo('#ListTeamsAthletes');
        TeamsAthletesParentsListView.appendTo('#ListTeamsAthletesParents');
        AgegroupsAthletesListView.appendTo('#ListAgegroupsAthletes');
        AgegroupsAthletesParentsListView.appendTo('#ListAgegroupsAthletesParents');

        function activate() {
            $q.all([authenticationService.getRight('UZENETIR')]).then(function (results) {
                vm.UZENETIR = results[0];
                if (!vm.UZENETIR) {
                    log.permissionError(true);
                }
            });

            getUserId();
            vm.toolbarItems = [
                {empid: 1, tooltiptext: gettextCatalog.getString('msgSend'), spriteCss: 'msgtools newSend'},
                {empid: 2, tooltiptext: gettextCatalog.getString('msgRecieved'), spriteCss: 'msgtools msgrcvd'},
            ];

            vm.toolbarPopupItems = [
                {empid: 1, tooltiptext: gettextCatalog.getString('msgOk'), spriteCss: 'msgtools Accept'},
                {empid: 2, tooltiptext: gettextCatalog.getString('msgCancel'), spriteCss: 'msgtools Back'}
            ];
            // ÖSSZES
            dataservice.Cimjegyzek().then(function (data) {
                MultiSelect.dataSource = data.itemsList;
            });
            $q.all([paramHelper.getParams([], ['newMessage.Message', 'newMessage.OriginalSenderId', 'newMessage.OriginalSenderName'])]).then(function (result) {
                vm.Message = result[0]['newMessage.Message'];
                vm.RecipientsIds = [];
                vm.RecipientsNames = '';
                vm.RecipientsNamesArr = [];
                if (result[0]['newMessage.OriginalSenderId'] > 0) {
                    vm.RecipientsIds.push(result[0]['newMessage.OriginalSenderId']);
                }
                vm.RecipientsNames = result[0]['newMessage.OriginalSenderName'];
            });
        }
    }
})();
