(function () {
    'use strict';

    angular
        .module('app.administration.assetManagement')
        .controller('AssetManagementController', AssetManagementController);

    function AssetManagementController($q, paramHelper, gettextCatalog, $state, dataservice, authenticationService, $rootScope, $scope, log, $timeout, $filter) {
        $rootScope.title = gettextCatalog.getString('AssetManagement');
        var vm = this;
        $rootScope.vm = vm;
        vm.userid = authenticationService.getAuthData().id;
        vm.groupList = [];
        vm.groupId = undefined;
        vm.dropdownDataBound = dropdownDataBound;
        vm.groupSelect = groupSelect;
        vm.athleteSelect = athleteSelect;
        vm.coachSelect = coachSelect;
        vm.Issue = Issue;
        vm.choosen = -1;

        vm.filter = 'issuable';
        vm.types = [];
        vm.assets = [];
        vm.athletes = [];
        vm.coaches = [];
        vm.selectedAssets = [];
        vm.athletecoachList = [
            {value: 'athlete', text: gettextCatalog.getString('Athlete')},
            {value: 'coach', text: gettextCatalog.getString('User')}
        ];
        vm.assetFilterList = [
            {value: 'issuable', text: gettextCatalog.getString('IssuableOnly')},
            {value: 'issued', text: gettextCatalog.getString('IssuedOnly')},
            {value: 'all', text: gettextCatalog.getString('All')},
            {value: 'active', text: gettextCatalog.getString('ActiveOnly')},
            {value: 'deleted', text: gettextCatalog.getString('DeletedOnly')}
        ];
        vm.assetTypeColumns = [
            {type: 'checkbox', textAlign: 'center', width: 45},
            {field: 'TipusId', visible: false, isPrimaryKey: true},
            {field: 'TipusNev', headerText: gettextCatalog.getString('AssetType'), textAlign: 'left'},
            {field: 'aktivDarab', headerText: gettextCatalog.getString('ActiveItem'), textAlign: 'left'},
            {field: 'inaktivDarab', headerText: gettextCatalog.getString('InactiveItem'), textAlign: 'left'},
            {field: 'szabadDarab', headerText: gettextCatalog.getString('Issuable'), textAlign: 'left'}
        ];

        vm.assetColumns = [
            {type: 'checkbox', textAlign: 'center', width: 45},
            {field: 'EszkozId', visible: false, isPrimaryKey: true},
            {field: 'Megnevezes', headerText: gettextCatalog.getString('Megnevezes'), textAlign: 'left'},
            {field: 'Leiras', headerText: gettextCatalog.getString('Leiras'), textAlign: 'left'},
            {field: 'Azonosito', headerText: gettextCatalog.getString('Azonosito'), textAlign: 'left'},
            {field: 'Meret', headerText: gettextCatalog.getString('Meret'), textAlign: 'left'},
            {field: 'Ar', headerText: gettextCatalog.getString('Ar'), textAlign: 'left'},
            {field: 'Szabad', headerText: gettextCatalog.getString('Issuable'), textAlign: 'left', displayAsCheckBox: true},
            {field: 'deleted', headerText: gettextCatalog.getString('Deleted'), textAlign: 'left', displayAsCheckBox: true},
            {field: 'NalaVan', headerText: gettextCatalog.getString('WhoHasIt'), textAlign: 'left'}
        ];

        vm.athleteColumns = [
            {field: 'SportoloId', visible: false, isPrimaryKey: true},
            {field: 'SportoloNev', headerText: gettextCatalog.getString('AthleteName'), textAlign: 'left'},
            {field: 'Section', headerText: gettextCatalog.getString('Section'), textAlign: 'left'},
            {field: 'AgeGroup', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left'}
        ];

        vm.coachColumns = [
            {field: 'EdzoId', visible: false, isPrimaryKey: true},
            {field: 'EdzoNev', headerText: gettextCatalog.getString('CoachName'), textAlign: 'left'},
            {field: 'Section', headerText: gettextCatalog.getString('Section'), textAlign: 'left'},
            {field: 'AgeGroup', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left'}
        ];

        vm.AddAssetType = AddAssetType;
        vm.EditAssetType = EditAssetType;
        vm.DeleteAssetType = DeleteAssetType;

        vm.AddAsset = AddAsset;
        vm.EditAsset = EditAsset;
        vm.DeleteAsset = DeleteAsset;
        vm.UndeleteAsset = UndeleteAsset;

        vm.filterSelect = filterSelect;

        vm.SelectAll = SelectAll;
        vm.clearSelection = clearSelection;

        vm.IssuePopup = IssuePopup;
        vm.TakeBack = TakeBack;
        vm.issueToSelect = issueToSelect;

        vm.ESZKNYILVMEGT = false;
        vm.ESZKNYILVMOD = false;
        vm.ESZKMOZG = false;
        vm.AddAssetTypeEnabled = false;
        vm.EditAssetTypeEnabled = false;
        vm.DeleteAssetTypeEnabled = false;
        vm.AddAssetEnabled = false;
        vm.EditAssetEnabled = false;
        vm.DeleteAssetEnabled = false;
        vm.UndeleteAssetEnabled = false;
        vm.IssueEnabled = false;
        vm.TakeBackEnabled = false;
        vm.issueToAthlete = false;
        vm.issueToCoach = false;
        vm.chooseEnabled = false;

        var TypeGrid2 = null;
        var AssetGrid2 = null;
        var AthleteGrid2 = null;
        var CoachGrid2 = null;

        CreateTypeGrid();
        CreateAssetGrid();
        CreateAthleteGrid();
        CreateCoachGrid();

        activate();

        function activate() {
            $q.all([authenticationService.getRight('ESZKNYILVMEGT'), authenticationService.getRight('ESZKNYILVMOD'), authenticationService.getRight('ESZKMOZG')]).then(function (data) {
                $timeout(function () {
                    vm.ESZKNYILVMEGT = data[0];
                    vm.ESZKNYILVMOD = data[1];
                    vm.ESZKMOZG = data[2];
                    if (vm.ESZKNYILVMEGT) {
                        GridRefresh();
                        if (vm.ESZKNYILVMOD) {
                            vm.AddAssetTypeEnabled = true;
                        }
                    } else {
                        log.permissionError(true);
                    }
                }, 100);
            });
        }

        function CreateAthleteGrid() {
            AthleteGrid2 = new ej2.grids.Grid({
                dataSource: vm.athletes,
                columns: vm.athleteColumns,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'Both',
                selectionSettings: {type: 'single', mode: 'Row'},
                rowSelected: function (args) {
                    athleteSelect(args);
                }
            });
            AthleteGrid2.appendTo('#AthleteGrid2');
        }

        function CreateCoachGrid() {
            CoachGrid2 = new ej2.grids.Grid({
                dataSource: vm.coaches,
                columns: vm.coachColumns,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'Both',
                selectionSettings: {type: 'single', mode: 'Row'},
                rowSelected: function (args) {
                    coachSelect(args);
                }
            });
            CoachGrid2.appendTo('#CoachGrid2');
        }

        function CreateTypeGrid() {
            TypeGrid2 = new ej2.grids.Grid({
                dataSource: vm.types,
                columns: vm.assetTypeColumns,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'Both',
                selectionSettings: {type: 'Single', mode: 'Row'},
                rowSelected: function (args) {
                    assetTypeSelect(args);
                },
                rowSelecting: function () {
                    TypeGrid2.clearSelection();
                },
                checkBoxChange: function (args) {
                    $rootScope.checkBoxChangeOnlyOne(args, TypeGrid2);
                }
            });
            TypeGrid2.appendTo('#TypeGrid2');
        }

        function CreateAssetGrid() {
            AssetGrid2 = new ej2.grids.Grid({
                dataSource: vm.assets,
                columns: vm.assetColumns,
                allowPaging: false,
                allowSorting: true,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'Both',
                selectionSettings: {type: 'Multiple', mode: 'Row'},
                rowSelected: function () {
                    assetSelectOrDeselect();
                },
                rowDeselected: function () {
                    assetSelectOrDeselect();
                }
            });
            AssetGrid2.appendTo('#AssetGrid2');
        }

        // ESZKÖZ TÍPUSOK
        function AddAssetType() {
            swal({
                title: gettextCatalog.getString('CreateAssetType'),
                type: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (!inputValue || inputValue.value.length < 1 || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    log.errorMsg(gettextCatalog.getString('AssetTypeNameMustNotBeEmpty'));
                } else {
                    dataservice.AssetManager({TipusId: -1, TipusNev: inputValue.value}).then(function (response) {
                        log.successMsg(gettextCatalog.getString('saveSuccess'));
                        vm.editDeleteMainTypeEnabled = false;
                        GridRefresh(response.TipusId);
                    });
                }
            });
        }

        function EditAssetType() {
            swal({
                title: gettextCatalog.getString('EditAssetType'),
                type: 'info',
                input: 'text',
                inputValue: vm.editype.TipusNev,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (inputValue) {
                if (!inputValue || inputValue.value.length < 1 || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    log.errorMsg(gettextCatalog.getString('AssetTypeNameMustNotBeEmpty'));
                } else {
                    dataservice.AssetManager({TipusId: vm.editype.TipusId, TipusNev: inputValue.value}).then(function () {
                        log.successMsg(gettextCatalog.getString('saveSuccess'));
                        vm.editDeleteMainTypeEnabled = false;
                        GridRefresh(vm.editype.TipusId);
                    });
                }
            });
        }

        function DeleteAssetType() {
            var warningText = '';
            if (vm.assets.length > 0) {
                log.errorMsg(gettextCatalog.getString('Only empty asset types can be deleted.'));
                return;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected asset type?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this asset type'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.AssetManager({TipusId: vm.editype.TipusId, TipusTorles: true}).then(function () {
                        vm.addNewSubType = false;
                        GridRefresh();
                    });
                }
            });
        }

        // ESZKÖZÖK
        function AddAsset() {
            var HTMLtext = createHtmlSwalContent([
                {id: 'Megnevezes', text: gettextCatalog.getString('Megnevezes'), value: ''},
                {id: 'Leiras', text: gettextCatalog.getString('Leiras'), value: ''},
                {id: 'Azonosito', text: gettextCatalog.getString('Azonosito'), value: ''},
                {id: 'Meret', text: gettextCatalog.getString('Meret'), value: ''},
                {id: 'Ar', text: gettextCatalog.getString('Ar'), value: ''},
                {id: 'darab', text: gettextCatalog.getString('NumberMassAdd'), value: ''}
            ]);
            swal({
                title: gettextCatalog.getString('CreateAsset'),
                html: HTMLtext,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.newAsset = {};
                    vm.newAsset.EszkozId = -1;
                    vm.newAsset.TipusId = vm.editype.TipusId;
                    vm.newAsset.Megnevezes = document.getElementById('Megnevezes').value;
                    vm.newAsset.Leiras = document.getElementById('Leiras').value;
                    vm.newAsset.Azonosito = document.getElementById('Azonosito').value;
                    vm.newAsset.Meret = document.getElementById('Meret').value;
                    vm.newAsset.Ar = document.getElementById('Ar').value;
                    vm.newAsset.darab = document.getElementById('darab').value;
                    if (vm.newAsset.Megnevezes && vm.newAsset.Megnevezes.length > 0) {
                        dataservice.AssetManager(vm.newAsset).then(function () {
                            log.successMsg(gettextCatalog.getString('saveSuccess'));
                            vm.editDeleteMainTypeEnabled = false;
                            GridRefresh(vm.editype.TipusId);
                        });
                    } else {
                        log.errorMsg(gettextCatalog.getString('AssetNameMustNotBeEmpty'));
                    }
                }
            });
        }

        function EditAsset() {
            if (getSelectedAssetIds().length != 1) {
                log.errorMsg(gettextCatalog.getString('MultipleAssetsCannotBeEditedAtOnce'));
                return;
            }
            console.log('EDIT ASSET vm.editAsset', vm.editAsset);
            var HTMLtext = createHtmlSwalContent([
                {id: 'Megnevezes', text: gettextCatalog.getString('Megnevezes'), value: vm.editAsset.Megnevezes},
                {id: 'Leiras', text: gettextCatalog.getString('Leiras'), value: vm.editAsset.Leiras},
                {id: 'Azonosito', text: gettextCatalog.getString('Azonosito'), value: vm.editAsset.Azonosito},
                {id: 'Meret', text: gettextCatalog.getString('Meret'), value: vm.editAsset.Meret},
                {
                    id: 'Ar',
                    text: gettextCatalog.getString('Ar'),
                    value: vm.editAsset.Ar ? vm.editAsset.Ar.toString() : ''
                },
                {id: 'AssetType', text: gettextCatalog.getString('AssetType'), value: '', dd: true}
            ]);
            swal({
                title: gettextCatalog.getString('EditAsset'),
                html: HTMLtext,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.editAsset.Megnevezes = document.getElementById('Megnevezes').value;
                    vm.editAsset.Leiras = document.getElementById('Leiras').value;
                    vm.editAsset.Azonosito = document.getElementById('Azonosito').value;
                    vm.editAsset.Meret = document.getElementById('Meret').value;
                    vm.editAsset.Ar = document.getElementById('Ar').value;
                    if (vm.editAsset.Megnevezes && vm.editAsset.Megnevezes.length > 0) {
                        dataservice.AssetManager(vm.editAsset).then(function () {
                            vm.EditAssetEnabled = false;
                            vm.DeleteAssetEnabled = false;
                            log.successMsg(gettextCatalog.getString('saveSuccess'));
                            vm.editDeleteMainTypeEnabled = false;
                            GridRefresh(vm.editAsset.TipusId);
                        });
                    } else {
                        log.errorMsg(gettextCatalog.getString('AssetNameMustNotBeEmpty'));
                    }
                }
            });
            angular.element('#DropDownSwal').ejDropDownList({
                dataSource: vm.types,
                select: function (arg) {
                    vm.editAsset.TipusId = arg.value;
                },
                value: vm.editAsset.TipusId,
                width: '100%'
            });
        }

        function DeleteAsset() {
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected asset?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this asset'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.DeleteUndeleteAssets({
                        action: 'delete',
                        assetList: getSelectedAssetIds()
                    }).then(function () {
                        vm.EditAssetEnabled = false;
                        vm.DeleteAssetEnabled = false;
                        GridRefresh(vm.editype.TipusId);
                    });
                }
            });
        }

        function UndeleteAsset() {
            swal({
                title: gettextCatalog.getString('Are you sure you want to undelete the selected asset?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, undelete this asset'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.DeleteUndeleteAssets({
                        action: 'undelete',
                        assetList: getSelectedAssetIds()
                    }).then(function () {
                        vm.EditAssetEnabled = false;
                        vm.DeleteAssetEnabled = false;
                        GridRefresh(vm.editype.TipusId);
                    });
                }
            });
        }

        function GridRefresh(tipusId) {
            console.log('GridRefresh');
            clearSelection();
            vm.EditAssetTypeEnabled = false;
            vm.DeleteAssetTypeEnabled = false;
            AssetGrid2.dataSource = vm.assets;
            AssetGrid2.refresh();
            dataservice.assetTypeList().then(function (data) {
                vm.types = data.itemsList;
                vm.types.forEach(function (item) {
                    item.value = item.TipusId;
                    item.text = item.TipusNev;
                });
                TypeGrid2.dataSource = vm.types;
                var index = $filter('getIndex')(vm.types, 'TipusId', tipusId);
                if (index !== false && index !== null && index >= 0) {
                    $timeout(function () {
                        TypeGrid2.selectRows([index]);
                        $scope.$apply();
                    }, 10);
                }
            });
        }

        function assetTypeSelect(args) {
            log.infoMsg(gettextCatalog.getString('DetailsAreShownInTheGridBelow'));
            clearSelection();
            if (vm.ESZKNYILVMOD) {
                if (vm.filter != 'deleted') {
                    vm.AddAssetEnabled = true;
                    vm.EditAssetEnabled = false;
                    vm.DeleteAssetEnabled = false;
                    vm.UndeleteAssetEnabled = false;
                    vm.EditAssetTypeEnabled = true;
                    vm.DeleteAssetTypeEnabled = true;
                }
            }
            vm.editype = args.data;
            getAssetsByType(args.data.TipusId, vm.filter);
            $scope.$apply();
        }

        function assetSelectOrDeselect() {
            $timeout(function () {
                vm.selectedAssets = getSelectedAssets();
                console.log('vm.selectedAssets', vm.selectedAssets.length, vm.selectedAssets);
                vm.DeleteAssetEnabled = false;
                vm.UndeleteAssetEnabled = false;
                vm.EditAssetEnabled = false;
                vm.IssueEnabled = false;
                vm.TakeBackEnabled = false;
                if (vm.selectedAssets.length == 1) {
                    vm.editAsset = vm.selectedAssets[0];
                    if (vm.ESZKNYILVMOD) {
                        if (vm.filter != 'deleted') {
                            vm.AddAssetEnabled = true;
                        }
                        if (vm.editAsset.Aktiv && vm.editAsset.Szabad) {
                            console.log('vm.ESZKNYILVMOD', vm.ESZKNYILVMOD);
                            vm.EditAssetEnabled = true;
                            vm.DeleteAssetEnabled = true;
                        }
                        vm.UndeleteAssetEnabled = !vm.editAsset.Aktiv;
                    }
                    if (vm.ESZKMOZG) {
                        vm.IssueEnabled = allIssuable();
                        vm.TakeBackEnabled = allIssued();
                    }
                } else if (vm.selectedAssets.length > 1) {
                    vm.editAsset = null;
                    vm.DeleteAssetEnabled = allIssuable();
                    vm.UndeleteAssetEnabled = allDeleted();
                    console.log('EZUISlefut');
                    vm.EditAssetEnabled = false;
                    if (vm.ESZKMOZG) {
                        vm.IssueEnabled = allIssuable();
                        vm.TakeBackEnabled = allIssued();
                    }
                }
            }, 100);
        }

        // MINDEGYIK TÖRÖLT
        function allDeleted() {
            var ret = true;
            vm.selectedAssets.forEach(function (asset) {
                if (asset.Aktiv == true) {
                    ret = false;
                }
            });
            return ret;
        }

        // MINDEGYIK SZABAD
        function allIssuable() {
            var ret = true;
            vm.selectedAssets.forEach(function (asset) {
                if (asset.Szabad == false || asset.Aktiv == false) { // Ha valamelyik ki van adva, vagy törölt akkor return false
                    ret = false;
                }
            });
            return ret;
        }

        // MINDEGYIK KI VAN ADVA
        function allIssued() {
            var ret = true;
            vm.selectedAssets.forEach(function (asset) {
                if (asset.Szabad == true || asset.Aktiv == false) { // Ha valamelyik Nincs kiadva, vagy törölt akkor return false
                    ret = false;
                }
            });
            return ret;
        }

        function getAssetsByType(TipusId) {
            clearSelection();
            dataservice.AssetsByType(TipusId, vm.filter).then(function (data) {
                vm.assets = data.data;
                AssetGrid2.dataSource = vm.assets;
            });
        }

        function filterSelect(arg) {
            console.log('filterSelect', arg);
            clearSelection();
            vm.filter = arg.value;
            vm.AddAssetEnabled = (vm.filter != 'deleted');
            vm.EditAssetEnabled = false;
            vm.DeleteAssetEnabled = false;
            vm.UndeleteAssetEnabled = false;
            if (vm.editype && vm.editype.TipusId > 0) {
                getAssetsByType(vm.editype.TipusId);
            }
        }

        function createHtmlSwalContent(arr) {
            var HTMLtext = '<div style="margin-top: 10px;" >';
            arr.forEach(function (item) {
                HTMLtext += '<div class="row">';
                if (item.hasOwnProperty('dd')) {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:5px;">' + item.text + '</label>';
                    HTMLtext += '<input class="col-lg-6 col-lg-offset-6" id="DropDownSwal" ej-dropdownlist></input><br>';
                } else {
                    HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:5px;">' + item.text + '</label>';
                    if (item.value && item.value.length > 0) {
                        HTMLtext += '<input type="text" value="' + item.value + '" id="' + item.id + '"></input><br>';
                    } else {
                        HTMLtext += '<input type="text" value="" id="' + item.id + '"></input><br>';
                    }
                }
                HTMLtext += '</div>';
            });
            HTMLtext += '</div><br>';
            return HTMLtext;
        }

        function getSelectedAssets() {
            vm.selectedAssets = AssetGrid2.getSelectedRecords();
            return vm.selectedAssets;
        }

        function getSelectedAssetIds() {
            var selectedAssets = getSelectedAssets();
            var assetIds = [];
            selectedAssets.forEach(function (asset) {
                assetIds.push(asset.EszkozId);
            });
            return assetIds;
        }

        function clearSelection() {
            vm.IssueEnabled = false;
            vm.TakeBackEnabled = false;
            AssetGrid2.clearSelection();
        }

        function SelectAll() {
            var indexes = [];
            for (var i = 0; i < vm.assets.length; i++) {
                indexes.push(i);
            }
            AssetGrid2.selectRows(indexes);
        }

        function IssuePopup() {
            angular.element('#issueDialog').data('ejDialog').open();
        }

        function TakeBack() {
            angular.element('#issueDialog').data('ejDialog').close();
            swal({
                title: gettextCatalog.getString('TakeBackAssetComment'),
                type: 'info',
                input: 'text',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok')
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return;
                }
                dataservice.AssetTakeBack(getSelectedAssetIds(), inputValue.value).then(function () {
                    log.successMsg('TakenBackSuccessfully');
                    getAssetsByType(vm.editype.TipusId);
                });
            });
        }

        function issueToSelect(arg) {
            vm.chooseEnabled = false;
            switch (arg.value) {
                case 'athlete':
                    getSections(vm.userid);
                    vm.issueToAthlete = true;
                    vm.issueToCoach = false;
                    vm.dropdownlabel = gettextCatalog.getString('Section');
                    break;
                case 'coach':
                    getUserGroups();
                    vm.issueToAthlete = false;
                    vm.issueToCoach = true;
                    vm.dropdownlabel = gettextCatalog.getString('UserGroup');
                    break;
            }
        }

        function getSections(userid) {
            dataservice.sectionDropDownList(userid).then(function (data) {
                vm.groupList = data.itemsList;
            });
        }

        function getUserGroups() {
            dataservice.userGroupList().then(function (data) {
                vm.groupList = data.itemsList;
            });
        }

        function dropdownDataBound() {
            if (vm.groupList.length > 0) {
                vm.groupId = vm.groupList[0].value;
            }
        }

        function groupSelect(arg) {
            vm.chooseEnabled = false;
            vm.groupId = arg.value;
            if (vm.issueToAthlete) {
                AthletesInSection(arg.value);
            } else if (vm.issueToCoach) {
                UsersInGroup(arg.value);
            }
        }

        function athleteSelect(arg) {
            $timeout(function () {
                vm.chooseEnabled = true;
                vm.choosen = arg.data.SportoloId;
            }, 100);
        }

        function coachSelect(arg) {
            $timeout(function () {
                vm.chooseEnabled = true;
                vm.choosen = arg.data.EdzoId;
            }, 100);
        }

        function Issue() {
            angular.element('#issueDialog').data('ejDialog').close();
            swal({
                title: gettextCatalog.getString('IssueAssetComment'),
                type: 'info',
                input: 'text',
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok')
            }).then(function (inputValue) {
                if (!inputValue || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return;
                }
                var edzo = 0;
                var sportolo = 0;
                if (vm.issueToAthlete) {
                    sportolo = vm.choosen;
                } else if (vm.issueToCoach) {
                    edzo = vm.choosen;
                }
                dataservice.AssetIssue(getSelectedAssetIds(), edzo, sportolo, inputValue.value).then(function () {
                    log.successMsg('IssuedSuccessfully');
                    getAssetsByType(vm.editype.TipusId);
                });
            });
        }

        function AthletesInSection(section) {
            dataservice.AthletesInSection(section).then(function (data) {
                vm.athletes = data.itemsList;
                AthleteGrid2.dataSource = vm.athletes;
            });
        }

        function UsersInGroup(usergroup) {
            dataservice.UsersInGroup(usergroup).then(function (data) {
                vm.coaches = data.itemsList;
                CoachGrid2.dataSource = vm.coaches;
            });
        }
    }
})();
