(function () {
    'use strict';

    angular
        .module('app.dashboard')
        .controller('DashboardController', DashboardController);

    function DashboardController($q, dataservice, log, DOMAIN, $location, localStorageService, paramHelper, $state, $scope, authenticationService, gettextCatalog, $rootScope, $filter, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MainPage');

        var printOpened = false;

        vm.messageCount = 0;
        vm.printDashboard = printDashboard;
        vm.clearLastViewedLinks = clearLastViewedLinks;
        //Grid nyomtatás, exportálás nyomógomb
        vm.datagridExpiringDocuments = [];
        vm.dataMonthlyAttendance = [];
        vm.columnsMonthlyAttendance = [];
        vm.dataMonthlyCompetitionPlan = [];
        vm.dataWeeklyCompetitionPlan = [];
        vm.columnsMonthlyCompetitionPlan = [];
        vm.dataYearlyCompetitionPlan = [];
        vm.columnsYearlyCompetitionPlan = [];
        vm.dataGridLinks = [];
        vm.columnsGridLinks = [];
        vm.dataUnpaidFees = [];
        vm.columnsUnpaidFees = [];
        vm.dataUnpaidFeesTrainer = [];
        vm.columnsUnpaidFeesTrainer = [];

        vm.goToNewUser = goToNewUser;
        vm.goToNewAthletes = goToNewAthletes;
        vm.goToNewAgeGroup = goToNewAgeGroup;
        vm.goToMonthlyAttendance = goToMonthlyAttendance;
        vm.goToMonthlyCompetitonPlan = goToMonthlyCompetitonPlan;
        vm.goToAnnualSeasonPlan = goToAnnualSeasonPlan;
        vm.openSportServiceFeeRegister = openSportServiceFeeRegister;
        vm.openSportServiceFeeArrears = openSportServiceFeeArrears;
        vm.openWeeklyPlan = openWeeklyPlan;
        vm.addNewLink = addNewLink;

        vm.editLink = editLink;
        vm.editLinkSave = editLinkSave;
        vm.deleteLink = deleteLink;
        vm.closeeditLinksWindow = closeeditLinksWindow;
        vm.closenewLinksWindow = closenewLinksWindow;
        vm.newLinkSave = newLinkSave;

        vm.MessageSeen = gettextCatalog.getString('MessageSeen');
        vm.ReadMessage = gettextCatalog.getString('ReadMessage');
        vm.MessageDelete = gettextCatalog.getString('MessageDelete');
        vm.LinkData = gettextCatalog.getString('LinkData');
        //Kell, mert script-ből nem veszi fel a fordítást
        vm.Edit = gettextCatalog.getString('Edit');
        vm.PayIn = gettextCatalog.getString('PayIn');

        vm.printing = false;

        vm.reportParameters = [];
        vm.printOptions = {
            messages: false,
            mthAttendance: false,
            mthCmptPlan: false,
            yrCmptPlan: false,
            unpayedFee: false,
            stat: false,
        };

        if (DOMAIN.justPsycho === 1) {
            $location.path('/athletesManagement/athletesList');
        }

        // gridek generálása
        // 1.
        vm.columnsgridExpiringDocuments = [];
        vm.columnsgridExpiringDocuments = [
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: 120,
                template: '#datemplateDataColumn1'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'KorosztalyNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Athlete'),
                textAlign: 'center',
                width: 120,
                template: '#datemplateDataColumn2'
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center',
                width: 150
            },
            {
                field: 'Tipus',
                headerText: gettextCatalog.getString('Type'),
                textAlign: 'center',
                width: 150
            }
        ];
        var gridExpiringDocuments = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsgridExpiringDocuments,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridExpiringDocuments'), $scope);
                }
            }
        });

        gridExpiringDocuments.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridExpiringDocuments);
        };

        // 2.
        vm.columnsMonthlyAttendance = [];
        vm.columnsMonthlyAttendance = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                width: 60,
                template: '#datemplateDataColumn4'
            },
            {
                field: 'Edzo',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                template: '#datemplateDataColumn3'
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center'
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center'
            },
            {
                field: 'CsoportLetszam',
                headerText: gettextCatalog.getString('groupStaffNumbers'),
                textAlign: 'center'
            },
            {
                field: 'Erkezett',
                headerText: gettextCatalog.getString('Arrived'),
                textAlign: 'center'
            },
            {
                field: 'Tavozott',
                headerText: gettextCatalog.getString('Departed'),
                textAlign: 'center'
            },
            {
                field: 'Megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                textAlign: 'center'
            }
        ];

        var gridMonthlyAttendance = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsMonthlyAttendance,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridMonthlyAttendance'), $scope);
                }
            }
        });

        gridMonthlyAttendance.appendTo('#gridExpiringDocuments');
        gridMonthlyAttendance.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridMonthlyAttendance);
        };

        //3.
        vm.columnsMonthlyCompetitionPlan = [];
        vm.columnsMonthlyCompetitionPlan = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                width: 60,
                allowEditing: false,
                template: '#datemplateDataColumn6'
            },
            {
                field: 'Edzo',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#datemplateDataColumn5'
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'Megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                textAlign: 'center',
                allowEditing: false
            }
        ];
        vm.selectedRow = [];
        var gridMonthlyMonthlyCompetitionPlan = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsMonthlyCompetitionPlan,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridMonthlyMonthlyCompetitionPlan'), $scope);
                }
            }
        });

        gridMonthlyMonthlyCompetitionPlan.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridMonthlyMonthlyCompetitionPlan);
        };

        //4.

        vm.columnsWeeklyCompetitionPlan = [];
        vm.columnsWeeklyCompetitionPlan = [
            {field: 'tervid', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center', width: 60, allowEditing: false,
                template: '#templateColumnETHetiLink'
            },
            {
                field: 'szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'korcsoport',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'edzo',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#templateColumnETHetiEdzo'
            },
            {
                field: 'hetfo',
                headerText: gettextCatalog.getString('Monday'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'vasarnap',
                headerText: gettextCatalog.getString('Sunday'),
                textAlign: 'center',
                allowEditing: false
            }
        ];

        var gridWeeklyCompetitionPlan = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsWeeklyCompetitionPlan,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridWeeklyCompetitionPlan'), $scope);
                }
            }
        });

        gridWeeklyCompetitionPlan.appendTo('#gridWeeklyCompetitionPlan');
        gridWeeklyCompetitionPlan.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridWeeklyCompetitionPlan);
        };

        //5.

        vm.columnsYearlyCompetitionPlan = [];
        vm.columnsYearlyCompetitionPlan = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                width: 60,
                allowEditing: false,
                template: '#datemplateDataColumn8'
            },
            {
                field: 'Edzo',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#datemplateDataColumn7'
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                allowEditing: false
            }
        ];

        var gridYearlyCompetitionPlan = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsYearlyCompetitionPlan,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridYearlyCompetitionPlan'), $scope);
                }
            }
        });

        gridYearlyCompetitionPlan.appendTo('#gridYearlyCompetitionPlan');
        gridYearlyCompetitionPlan.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridYearlyCompetitionPlan);
        };

        //6.
        vm.columnsUnpaidFees = [];
        vm.columnsUnpaidFees = [
            {
                field: 'Link',
                headerText: '',
                textAlign: 'center',
                width: 60,
                allowEditing: false,
                template: '#datemplateDataColumn11'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#datemplateDataColumn9'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'Egyenleg',
                headerText: gettextCatalog.getString('Balance'),
                textAlign: 'center',
                allowEditing: false
            },
            {
                field: 'Link1',
                headerText: gettextCatalog.getString('PayIn'),
                textAlign: 'center',
                allowEditing: false,
                template: '#datemplateDataColumn10'
            }
        ];

        var gridUnpaidFees = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsUnpaidFees,
            beforePrint: function (args) {
                $rootScope.beforePrintGrid(args, ['Link', 'Link1']);
            },
            printComplete: function (args) {
                $rootScope.printCompleteGrid(args, ['Link', 'Link1']);
            },
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridUnpaidFees'), $scope);
                }
            }
        });

        gridUnpaidFees.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridUnpaidFees);
        };

        //6.1.

        vm.columnsUnpaidFeesTrainer = [];
        vm.columnsUnpaidFeesTrainer = [
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Athlete'),
                textAlign: 'center',
                width: 120,
                allowEditing: false,
                template: '#datemplateDataColumn12'
            },
            {
                field: 'SzuletesiIdo',
                headerText: gettextCatalog.getString('BirthDate'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'center',
                width: 120,
                allowEditing: false,
                template: '#datemplateDataColumn13'
            },
            {
                field: 'Evfolyam',
                headerText: gettextCatalog.getString('SchoolYear'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                field: 'Befizetett',
                headerText: gettextCatalog.getString('PayIn'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                field: 'OktDijak',
                headerText: gettextCatalog.getString('EducationalFee'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                field: 'Egyenleg',
                headerText: gettextCatalog.getString('Balance'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            }
        ];

        var gridUnpaidFeesTrainer = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsUnpaidFeesTrainer,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridUnpaidFeesTrainer'), $scope);
                }
            }
        });

        gridUnpaidFeesTrainer.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridUnpaidFeesTrainer);
        };

        //7.
        vm.columnsGridLinks = [];
        vm.columnsGridLinks = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {
                field: 'Hivatkozas',
                headerText: gettextCatalog.getString('Link'),
                textAlign: 'center',
                width: 120,
                allowEditing: false,
                template: '#datemplateDataColumn14'
            },
            {
                field: 'Megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                field: 'Link',
                headerText: gettextCatalog.getString('LinkDesc'),
                textAlign: 'center',
                width: 120,
                allowEditing: false
            },
            {
                headerText: gettextCatalog.getString('Edit'), textAlign: 'center', width: 120, allowEditing: false,
                template: '#datemplateDataColumn15'
            }
        ];

        var gridLinks = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsGridLinks,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#gridLinks'), $scope);
                }
            }
        });
        gridLinks.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridLinks);
        };

        activate();

        function activate() {
            if (!localStorageService.get('_authData')) {
                return;
            }

            $q.all([
                authenticationService.getRight('EDZO'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('TECHIGAZGATO'),
                authenticationService.getRight('HJALAIR1'),
                authenticationService.getRight('HAVIVERSTALAIR1'),
                authenticationService.getRight('EVVERSTALAIR1'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('MODKOROKEZ'),
                authenticationService.getRight('MEGTHAVIJEL'),
                authenticationService.getRight('MEGTHAVIVERST'),
                authenticationService.getRight('MEGTEVVERST'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('FOLAPLINKEK'),
                getSystemSettings('FOLAPOLVASATLANOK'),
                getSystemSettings('FOLAPNYOMTATAS'),
                getSystemSettings('FOLAPELFOGADASRAVAROJELENLETIK'),
                getSystemSettings('FOLAPELFOGADASRAVAROHAVITERVEK'),
                getSystemSettings('FOLAPELFOGADASRAVAROEVESTERVEK'),
                getSystemSettings('FOLAPKIFIZETETLENTAGDIJAK'),
                getSystemSettings('FOLAPLINKEK'),
                getSystemSettings('FOLAPLEJARO'),
                getSystemSettings('FOLAPELFOGADASRAVAROHETITERV'),
                authenticationService.getRight('UZENETMEGT')
            ]).then(function (results) {
                vm.EDZO = results[0];
                vm.ADMIN = results[1];
                vm.SZAKOSZTVEZ = results[2];
                vm.TECHIGAZGATO = results[3];
                vm.HJALAIR1 = results[4];
                vm.HAVIVERSTALAIR1 = results[5];
                vm.EVVERSTALAIR1 = results[6];
                vm.MEGTFELH = results[7];
                vm.MODSPORTOLO = results[8];
                vm.MODKOROKEZ = results[9];
                vm.MEGTHAVIJEL = results[10];
                vm.MEGTHAVIVERST = results[11];
                vm.MEGTEVVERST = results[12];
                vm.MODSPORTOLO = results[13];
                vm.FOLAPLINKEK = results[14];
                vm.FOLAPOLVASATLANOK = results[15];
                vm.FOLAPNYOMTATAS = results[16];
                vm.FOLAPELFOGADASRAVAROJELENLETIK = results[17];
                vm.FOLAPELFOGADASRAVAROHAVITERVEK = results[18];
                vm.FOLAPELFOGADASRAVAROEVESTERVEK = results[19];
                vm.FOLAPKIFIZETETLENTAGDIJAK = results[20];
                //vm.FOLAPLINKEK = results[21];
                vm.FOLAPLEJARO = results[22];
                vm.FOLAPELFOGADASRAVAROHETITERV = results[23];
                vm.UZENETMEGT = results[24];

                dataservice.getUnAcceptedMonthlyAttendance().then(function (data) {
                    vm.dataMonthlyAttendance = data.itemsList;
                    gridMonthlyAttendance.dataSource = data.itemsList;
                    $timeout(function () {
                        gridMonthlyAttendance.appendTo('#gridMonthlyAttendance');
                    }, 100);
                });
                dataservice.getUnAcceptedMonthlyCompetitionPlan().then(function (data) {
                    vm.dataMonthlyCompetitionPlan = data.itemsList;
                    gridMonthlyMonthlyCompetitionPlan.dataSource = vm.dataMonthlyCompetitionPlan;
                    $timeout(function () {
                        gridMonthlyMonthlyCompetitionPlan.appendTo('#gridMonthlyMonthlyCompetitionPlan');
                    }, 100);
                });
                if (vm.FOLAPELFOGADASRAVAROHETITERV) {
                    dataservice.getUnAcceptedWeeklyCompetitionPlan().then(function (data) {
                        vm.FOLAPELFOGADASRAVAROHETITERV = data.isAdmin || data.isSectionLeader;
                        var dataList = data.itemsList;
                        dataList = $filter('dateStringFilter')(dataList, 'hetfo');
                        dataList = $filter('dateStringFilter')(dataList, 'vasarnap');
                        gridWeeklyCompetitionPlan.dataSource = dataList;
                        vm.dataWeeklyCompetitionPlan = dataList;
                        $timeout(function () {
                            gridWeeklyCompetitionPlan.appendTo('#gridWeeklyCompetitionPlan');
                        }, 100);
                    });
                }
                dataservice.getUnAcceptedYearlyCompetitionPlan().then(function (data) {
                    vm.dataYearlyCompetitionPlan = data.itemsList;
                    gridYearlyCompetitionPlan.dataSource = data.itemsList;
                    $timeout(function () {
                        gridYearlyCompetitionPlan.appendTo('#gridYearlyCompetitionPlan');
                    }, 100);
                });
                if (vm.FOLAPLINKEK) {
                    $timeout(function () {
                        gridLinks.appendTo('#gridLinks');
                        getLinks();
                    }, 100);
                }
                if (vm.ADMIN || vm.SZAKOSZTVEZ || vm.TECHIGAZGATO) {
                    dataservice.getUnPaidFees().then(function (data) {
                        vm.dataUnpaidFees = data.itemsList;
                        gridUnpaidFees.dataSource = data.itemsList;
                        $timeout(function () {
                            gridUnpaidFees.appendTo('#gridUnpaidFees');
                        }, 100);
                    });
                } else {
                    dataservice.getUnPaidFeesTrainer().then(function (data) {
                        vm.dataUnpaidFeesTrainer = data.itemsList;
                        gridUnpaidFeesTrainer.dataSource = data.itemsList;
                        $timeout(function () {
                            gridUnpaidFeesTrainer.appendTo('#gridUnpaidFeesTrainer');
                        }, 100);
                    });
                }

                dataservice.ExpiringDocuments().then(function (data) {
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Datum');
                    gridExpiringDocuments.dataSource = data.itemsList;
                    vm.datagridExpiringDocuments = data.itemsList;
                    //generálás csak a div létrehozása után lehet ami csak az ng-if után futhat le
                    $timeout(function () {
                        gridExpiringDocuments.appendTo('#gridExpiringDocuments');
                    }, 100);
                });
            });
        }

        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }

        function openSportServiceFeeRegister(sectionId, coachId) {
            var currentYear = new Date().getFullYear();
            paramHelper.setParams([
                {'sportServiceFeeRegister.sectionId': sectionId},
                {'sportServiceFeeRegister.coachId': coachId},
                {'sportServiceFeeRegister.year': currentYear}
            ]);
            $state.go('business.sportServiceFeeRegister');
        }

        function openSportServiceFeeArrears(sectionId, coachId) {
            var currentYear = new Date().getFullYear();
            paramHelper.setParams([
                {'sportServiceFeeArrears.sectionId': sectionId},
                {'sportServiceFeeArrears.coachId': coachId},
                {'sportServiceFeeArrears.year': currentYear}
            ]);
            $state.go('business.sportServiceFeeArrears');
        }

        function openWeeklyPlan(tervid) {
            paramHelper.setParams([
                {'weeklyTrainingPlan.trainingPlanId': tervid}
            ]);
            $state.go('administration.weeklyTrainingPlan.weeklyTrainingPlanReport');
        }

        function goToNewUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'newUser.userId': edzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAgeGroup(ageGroupId) {
            if (!vm.MODKOROKEZ) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'ageGroups.ageGroup': ageGroupId}]);
            $state.go('athletes.ageGroups.modifyAgeGroup');
        }

        function goToNewAthletes(athleteId) {
            if (!vm.MODSPORTOLO) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'athleteModify.sportoloId': athleteId}]);
            $state.go('athletes.athletesManagement.athleteModify.dataSheet');
        }

        function goToMonthlyAttendance(maId) {
            if (!vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'monthlyAttendance.id': maId}]);
            $state.go('administration.monthlyAttendance.monthlyAttendanceReport');
        }

        function goToAnnualSeasonPlan(asId) {
            if (!vm.MEGTEVVERST) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'AnnualSeasonPlanReport.Id': asId}]);
            $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
        }

        function goToMonthlyCompetitonPlan(mcpId, edzoId, szakosztalyId) {
            if (!vm.MEGTHAVIVERST) {
                log.permissionError();
                return;
            }
            var result = gridMonthlyMonthlyCompetitionPlan.dataSource.filter(function (v) {
                if (v.Id === mcpId) {
                    return v;
                }
            });
            paramHelper.setParams([{'competitiveMonthlyPlan.edzoId': edzoId},
                {'competitiveMonthlyPlan.edzoNev': result[0].Edzo},
                {'competitiveMonthlyPlan.szakosztalyNev': result[0].Szakosztaly},
                {'competitiveMonthlyPlan.szakosztalyId': szakosztalyId}, {'competitiveMonthlyPlan.competitivePlanId': mcpId}
            ]);
            $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport');
        }

        function printDashboard() {
            var existChecked = false;
            angular.forEach(vm.printOptions, function (value) {
                if (value) {
                    existChecked = true;
                }
            });

            if (existChecked === false) {
                log.errorMsg(gettextCatalog.getString('NoSelectedItemToPrint'));
                return;
            }
            vm.reportParameters = {
                'evesVers': vm.printOptions.yrCmptPlan,
                'felhasznalo': authenticationService.getAuthData().userFullName,
                'FelhasznaloId': authenticationService.getAuthData().id,
                'havijel': vm.printOptions.mthAttendance,
                'haviVers': vm.printOptions.mthCmptPlan,
                'nemBefTd': vm.printOptions.unpayedFee,
                'statisztika': vm.printOptions.stat,
                'szakosztTd': 'false',
                'uzenet': vm.printOptions.messages
            };
            $scope.$apply();

            if (printOpened) {
                angular.element('#printDialog').ejDialog('open');
            } else {
                printOpened = true;
                angular.element('#printDialog').ejDialog({
                    height: '99%',
                    width: '99%',
                    enablemodal: 'true',
                    'position-x': '0',
                    'position-y': '1',
                    target: '#mainContent',
                    enableresize: 'false'
                });
            }
        }

        function clearLastViewedLinks() {
            // Todo: isolated storage-be rakni a linket/onnan visszatölteni/törölni
        }

        function addNewLink() {
            if (!vm.FOLAPLINKEK) {
                log.permissionError();
                return;
            }
            var dialogObj = angular.element('#newlink').data('ejDialog');
            dialogObj.open();
        }

        function closenewLinksWindow() {
            var dialogObj = angular.element('#newlink').data('ejDialog');
            dialogObj.close();
        }

        function newLinkSave() {
            if (!vm.FOLAPLINKEK) {
                log.permissionError();
                return;
            }
            var dialogObj = angular.element('#newlink').data('ejDialog');
            dialogObj.close();
            if (vm.newLink && vm.newLink.Hivatkozas && vm.newLink.Hivatkozas.length > 0) {
                dataservice.saveNewLink(vm.newLink).then(function () {
                    getLinks();
                    log.successMsg('NewLinkSaved');
                    vm.newLink.Hivatkozas = '';
                    vm.newLink.Link = '';
                    vm.newLink.Megjegyzes = '';
                });
            } else {
                log.errorMsg(gettextCatalog.getString('EmptyLinkAddressCannotBeSaved'));
            }
        }

        function editLink(args) {
            if (!vm.FOLAPLINKEK) {
                log.permissionError();
                return;
            }
            var db = gridLinks.dataSource.length;

            for (var rec = 0; rec < db; rec++) {
                if (gridLinks.dataSource[rec].Id === args) {
                    vm.editedLink = gridLinks.dataSource[rec];
                    break;
                }
            }
            var dialogObj = angular.element('#editLinks').data('ejDialog');
            dialogObj.open();
        }

        function editLinkSave() {
            if (!vm.FOLAPLINKEK) {
                log.permissionError();
                return;
            }
            dataservice.saveLink(vm.editedLink).then(function () {
                getLinks();
                // var db = gridLinks.dataSource.length;
                // for (var rec = 0; rec < db; rec++) {
                //     if (gridLinks.dataSource[rec].Id === vm.editedLink.Id) {
                //         gridLinks.dataSource[rec] = vm.editedLink;
                //         vm.dataGridLinks[rec] = vm.editedLink;
                //
                //             console.log('save', gridLinks.dataSource);
                //             gridLinks.appendTo('#gridLinks');
                //     }
                // }
                closeeditLinksWindow();
                log.successMsg('LinkSaved');
            });
        }

        function deleteLink(arg) {
            if (!vm.FOLAPLINKEK) {
                log.permissionError();
                return;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected link?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this link'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    var p = {};
                    p.Id = arg;
                    dataservice.deleteLink(p).then(function () {
                        log.successMsg('LinkDeleted');
                        getLinks();
                    });
                }
            });
        }

        //Felugró ablak 'Mégsem' gomb click
        function closeeditLinksWindow() {
            var dialogObj = angular.element('#editLinks').data('ejDialog');
            dialogObj.close();
        }

        function getLinks() {
            dataservice.getLinks().then(function (data) {
                gridLinks.dataSource = data.itemsList;
            });
        }
    }
})();
